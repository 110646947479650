import React, { useState, useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import CharacterCount from "@tiptap/extension-character-count";

import "primeicons/primeicons.css";
import "./TextEditor.css";
const TextEditor = ({
  content = "",
  placeholder = "Masukan konten disini ....",
  onChange,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({}),
      CharacterCount.configure({}),
      Placeholder.configure({
        placeholder: placeholder,
      }),
    ],
    content: content,
    onUpdate({ editor }) {
      const html = editor.getHTML();
      onChange(html);
    },
  });

  useEffect(() => {
    return () => {
      editor?.destroy();
    };
  }, [editor]);

  return (
    <div className="text-editor">
      <hr />

      <EditorContent editor={editor} />
      <div className="info mt-2">
        {editor?.storage.characterCount.words()} words /{" "}
        {editor?.storage.characterCount.characters()} characters{" "}
      </div>
    </div>
  );
};

export default TextEditor;
