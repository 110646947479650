import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import api from "./axiosConfig";
import { useQuery, useMutation } from "react-query";

const CustomDropzone = ({ onFileUploaded, initialFileId }) => {
  const [filePreview, setFilePreview] = useState("");
  const [fileType, setFileType] = useState("");
  // Query untuk mengambil data file jika ada file ID
  const { data: fileData, isSuccess } = useQuery(
    ["fetchFile", initialFileId],
    async () => {
      const result = await api.post(`/filemanager/files/find`, {
        file: initialFileId,
      });

      return result.data;
    },
    {
      enabled: !!initialFileId,
    }
  );

  // Mutasi untuk mengupload file
  const fileMutation = useMutation(
    (formData) =>
      api.post("/filemanager/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    {
      onSuccess: (response) => {
        onFileUploaded(response.data.data);
        toast.success("File berhasil diupload");
      },
      onError: () => {
        toast.error("Gagal mengupload file");
      },
    }
  );

  useEffect(() => {
    if (initialFileId && isSuccess) {
      setFileType(fileData.data.mime_type);
      setFilePreview(fileData.data.url_file);
    }
  }, [initialFileId, isSuccess, fileData]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFileType(file.type);

      if (file.type.startsWith("image/") || file.type.startsWith("video/")) {
        const previewUrl = URL.createObjectURL(file);
        setFilePreview(previewUrl);
      } else if (file.type === "application/pdf") {
        setFilePreview(`/pdf-icon.jpg`);
      }

      const formData = new FormData();
      formData.append("file", file);

      fileMutation.mutate(formData);
    },
  });

  return (
    <div
      {...getRootProps({
        style: {
          border: "2px dashed #007bff",
          borderRadius: "5px",
          padding: "20px",
          textAlign: "center",
          margin: "10px",
          cursor: "pointer",
        },
      })}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop some files here, or click to select files</p>

      {filePreview && fileType.startsWith("image/") && (
        <img
          src={filePreview}
          alt="Preview"
          style={{ height: "150px", marginTop: "10px" }}
        />
      )}
      {filePreview && fileType === "application/pdf" && (
        <img
          src={`/pdf-icon.jpg`}
          alt="PDF cover"
          style={{ height: "150px", marginTop: "10px" }}
        />
      )}
      {filePreview && fileType.startsWith("video/") && (
        <video
          controls
          src={filePreview}
          style={{ height: "150px", marginTop: "10px" }}
        />
      )}
    </div>
  );
};

export default CustomDropzone;
