import React from "react";
import MenubarComponent from "./MenubarComponent";

const MainLayout = ({ children }) => {
  return (
    <div className="main-layout  ">
      <div className="z-color"></div>
      <MenubarComponent />
      <div className="content p-4">{children}</div>
      <style jsx>{`
        .main-layout {
          background-color: #f8f8f8;
          min-height: 100vh;
        }
      `}</style>
    </div>
  );
};

export default MainLayout;
