import { useNavigate } from "react-router-dom";
import TableDinamis from "../../util/dynamic/tableDinamis";
import moment from "moment";
import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const TransaksiPage = () => {
  const navigate = useNavigate();
  const [visibleId, setVisibleId] = useState(null);
  const [dialogContent, setDialogContent] = useState("");
  const handleDialogOpen = (rowData) => {
    setVisibleId(rowData.id);
    setDialogContent(rowData.data_pilgrim || "No content available.");
  };

  const handleDialogClose = () => {
    setVisibleId(null);
    setDialogContent("");
  };

  const renderDialogContent = () => {
    try {
      const parsedContent = JSON.parse(dialogContent);

      if (Array.isArray(parsedContent)) {
        return (
          <div>
            {parsedContent.map((item, index) => (
              <div key={index} className="card-jamaah-transaksi">
                <div className="mb-1">
                  <div>
                    Fullname: <b>{item.fullname}</b>
                  </div>
                </div>
                <div className="mb-1">
                  <div>
                    Email: <b>{item.email}</b>
                  </div>
                </div>
                <div className="mb-1">
                  <div>
                    Phone: <b>{item.phone}</b>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      }

      // Jika konten tidak berbentuk array atau gagal diparsing
      return <p>{dialogContent}</p>;
    } catch (e) {
      // Jika parsing gagal, tampilkan konten mentah
      return <p>{dialogContent}</p>;
    }
  };

  return (
    <div>
      <TableDinamis
        title="Transaksi Group"
        headerComponents={[
          () => (
            <Button
              label="Setup Payment Method"
              icon="pi pi-cog"
              onClick={() => navigate("/transaksi/payment/method")}
            />
          ),
        ]}
        id="transaksi"
        path="/transaksi/group"
        onRefresh={0}
        kolom={[
          {
            field: "code",
            label: "Kode",
            sort: true,
          },
          {
            field: "total",
            label: "Total",
            type: "currency",
            sort: true,
          },
          {
            field: "status",
            label: "Status",
            sort: true,
          },
          {
            field: "createdAt",
            label: "Created At",
            type: "date",
            format: "DD MMM yyyy",
            sort: true,
          },
          {
            field: "expiredAt",
            label: "Expired At",
            type: "custom",
            sort: true,
            component: (rowData) => (
              <span>
                {rowData.expiredAt
                  ? moment(rowData.expiredAt).format("lll")
                  : "-"}
              </span>
            ),
          },
        ]}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Lihat Jadwal"
              className="p-button-raised p-button-primary mr-2"
              onClick={() => navigate(`/jadwal/jamaah/${rowData.jadwal_id}`)}
            />
            <Button
              label="Detail"
              className="p-button-raised p-button-info mr-2"
              onClick={() => navigate(`/transaksi/${rowData.id}`)}
            />
            <Button
              label="List Jamaah"
              className="p-button-raised p-button-success mr-2"
              onClick={() => handleDialogOpen(rowData)}
            />
          </div>
        )}
      />
      <Dialog
        header="Detail Jamaah"
        visible={!!visibleId}
        style={{ width: "50vw" }}
        onHide={handleDialogClose}
      >
        {renderDialogContent()}
      </Dialog>
    </div>
  );
};

export default TransaksiPage;
