import React, { useState } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalForm from "../../util/dynamic/formDinamis";
import TableDinamis from "../../util/dynamic/tableDinamis";
import api from "../../util/axiosConfig";
import { Link, useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";

const Page = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectID, setSelectID] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [refresh, setRefresh] = useState(0);

  // Fungsi untuk menangani penambahan item baru
  const handleAdd = () => {
    setIsEditMode(false);
    setFormData({
      fullname: {
        label: "Name",
        value: "",
        type: "text",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      email: {
        label: "Email",
        value: "",
        type: "email",
        validation: {
          required: true,
          message: "Please enter a valid email address.",
        },
      },
      phone: {
        label: "Phone",
        value: "",
        type: "phone",
        validation: {
          required: true,
          minLength: 10,
          message: "Please enter a valid phone number.",
        },
      },
      brithday: {
        label: "Tanggal Lahir",
        value: "",
        type: "date",
        validation: {
          required: true,
        },
      },
      address: {
        label: "Alamat",
        value: "",
        type: "text",
        // validation: {
        //   required: true,
        //   minLength: 10,
        //   message: "Please enter a valid phone number.",
        // },
      },
      gender: {
        label: "Gender",
        value: "",
        type: "dropdown",
        options: [
          { label: "Male", value: "male" },
          { label: "Female", value: "female" },
        ],
        validation: { required: true, message: "Please select a gender." },
      },
      status_pilgrim: {
        label: "Status Jamaah",
        value: "",
        type: "dropdown",
        options: [
          { label: "Lead", value: "lead" },
          { label: "Jamaah", value: "pilgrim" },
          { label: "Alumni", value: "alumni" },
        ],
        validation: { required: true, message: "Please select a status." },
      },
    });
    setShowModal(true);
  };

  // Fungsi untuk menangani pengeditan item yang ada
  const handleEdit = async (id) => {
    setSelectID(id);
    const result = await api.get(`/jamaah/${id}`);
    setIsEditMode(true);
    setFormData({
      fullname: {
        label: "Name",
        value: result.data.data.fullname,
        type: "text",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      email: {
        label: "Email",
        value: result.data.data.email,
        type: "email",
        validation: {
          required: true,
          message: "Please enter a valid email address.",
        },
      },
      phone: {
        label: "Phone",
        value: result.data.data.phone,
        type: "phone",
        validation: {
          required: true,
          minLength: 10,
          message: "Please enter a valid phone number.",
        },
      },
      brithday: {
        label: "Tanggal Lahir",
        value: result.data.data.brithday,
        type: "date",
        validation: {
          required: true,
        },
      },
      address: {
        label: "Alamat",
        value: result.data.data.address,
        type: "text",
        // validation: {
        //   required: true,
        //   minLength: 10,
        //   message: "Please enter a valid phone number.",
        // },
      },
      gender: {
        label: "Gender",
        value: result.data.data.gender,
        type: "dropdown",
        options: [
          { label: "Male", value: "male" },
          { label: "Female", value: "female" },
        ],
        validation: { required: true, message: "Please select a gender." },
      },
      status_pilgrim: {
        label: "Status Jamaah",
        value: result.data.data.status_pilgrim,
        type: "dropdown",
        options: [
          { label: "Lead", value: "lead" },
          { label: "Jamaah", value: "pilgrim" },
          { label: "Alumni", value: "alumni" },
        ],
        validation: { required: true, message: "Please select a status." },
      },
    });
    setShowModal(true);
  };

  // Fungsi untuk menangani penyimpanan data
  const handleSave = async (data) => {
    try {
      if (!isEditMode) {
        await api.post(`/jamaah`, data);
      } else {
        await api.put(`/jamaah/${selectID}`, data);
      }
      toast.success("Data saved successfully!");
      setRefresh(refresh + 1);
      setShowModal(false);
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while saving data.");
      }
    }
  };

  // Fungsi untuk menangani penghapusan item
  const handleDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to delete this item?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await api.delete(`/jamaah/${id}`);
          toast.success("Data deleted successfully!");
          setRefresh(refresh + 1);
        } catch (error) {
          toast.error("An error occurred while deleting data.");
        }
      },
      reject: () => {
        toast.info("Delete action was canceled.");
      },
    });
  };

  return (
    <div className="w-full">
      {showModal && (
        <ModalForm
          visible={showModal}
          onHide={() => setShowModal(false)}
          onSave={handleSave}
          formData={formData}
          isEditMode={isEditMode}
        />
      )}

      <TableDinamis
        title="Jamaah"
        headerComponents={[
          () => (
            <Button
              label="Add Jamaah"
              icon="pi pi-plus"
              onClick={handleAdd}
              className="btn-core mr-2"
            />
          ),
        ]}
        id="jamaah"
        path="/jamaah"
        onRefresh={refresh}
        kolom={[
          {
            field: "fullname",
            label: "Name",
            sort: true,
          },
          {
            field: "email",
            label: "Email",
            sort: true,
          },
          {
            field: "phone",
            label: "Phone",
            sort: true,
          },
          {
            field: "brithday",
            label: "Tanggal Lahir",
            sort: true,
          },
          {
            field: "status_pilgrim",
            label: "Status",
            sort: true,
          },
        ]}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Detail"
              className="mr-2 font-bold"
              severity="info"
              outlined
              onClick={() => navigate(`/jamaah/dokument/${rowData.id}`)}
            />
            <Button
              label="Edit"
              className="mr-2 font-bold btn-succes-outlined"
              severity="success"
              outlined
              onClick={() => handleEdit(rowData.id)}
            />
            <Button
              label="Delete"
              className="mr-2 font-bold btn-danger-outlined"
              severity="danger"
              outlined
              onClick={() => handleDelete(rowData.id)}
            />
          </div>
        )}
      />

      <ConfirmDialog />
    </div>
  );
};

export default Page;
