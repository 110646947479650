import React from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Card } from "primereact/card";
import api from "../../util/axiosConfig";
import { toast } from "react-toastify";
import "primeflex/primeflex.css";

const EditPassword = () => {
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      await api.post("/auth/edit-password", data);
      toast.success("Password updated successfully");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("error while updating password");
      }
    }
    // Handle form submission
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <div className="p-d-flex p-jc-center p-ai-center p-mt-4">
      <Card
        title="Edit Password"
        className="p-shadow-5"
        style={{ width: "400px" }}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="p-field p-mb-4">
            <label htmlFor="oldPassword">Old Password</label>
            <Controller
              name="oldPassword"
              control={control}
              render={({ field }) => (
                <Password
                  id="oldPassword"
                  {...field}
                  feedback={false}
                  className={classNames({ "p-invalid": !!errors.oldPassword })}
                />
              )}
            />
            {getFormErrorMessage("oldPassword")}
          </div>
          <div className="p-field p-mb-4">
            <label htmlFor="newPassword">New Password</label>
            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <Password
                  id="newPassword"
                  {...field}
                  feedback={false}
                  className={classNames({ "p-invalid": !!errors.newPassword })}
                />
              )}
            />
            {getFormErrorMessage("newPassword")}
          </div>
          <div className="p-field p-mb-4">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <Password
                  id="confirmPassword"
                  {...field}
                  feedback={false}
                  className={classNames({
                    "p-invalid": !!errors.confirmPassword,
                  })}
                />
              )}
            />
            {getFormErrorMessage("confirmPassword")}
          </div>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-save"
            className="p-mt-2"
          />
        </form>
      </Card>
    </div>
  );
};

export default EditPassword;
