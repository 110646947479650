import React, { useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import api from "../util/axiosConfig";
import BarChart from "../chart/BarChart";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";

const fetchChartData = async (year) => {
  const { data } = await api.get("/dashboard/chart", {
    params: { year },
  });
  return data.data;
};

// const Page = () => {
//   const currentYear = moment().year();
//   const [year, setYear] = useState(currentYear);

//   const { data: chartData, isLoading } = useQuery(
//     ["chartData", year],
//     () => fetchChartData(year),
//     {
//       keepPreviousData: true,
//     }
//   );

//   const handleYearChange = (e) => {
//     setYear(e.value);
//   };

//   const yearOptions = [...Array(10)].map((_, i) => {
//     const y = currentYear - i;
//     return { label: y.toString(), value: y };
//   });

//   return (
//     <div className="p-d-flex p-flex-column p-ai-center p-p-4 w-full">
//       <Card className="p-shadow-5" style={{ width: "100%", maxWidth: "800px" }}>
//         <h1 className="p-text-center">Dashboard Chart</h1>
//         <div className="p-d-flex p-jc-center p-mb-4">
//           <Dropdown
//             value={year}
//             options={yearOptions}
//             onChange={handleYearChange}
//             placeholder="Select a Year"
//             className="p-mr-2"
//           />
//         </div>
//         {isLoading ? (
//           <div
//             className="p-d-flex p-jc-center p-ai-center"
//             style={{ height: "50vh" }}
//           >
//             <ProgressSpinner />
//           </div>
//         ) : (
//           <BarChart data={chartData} />
//         )}
//       </Card>
//     </div>
//   );
// };

const Page = () => {
  return <div></div>;
};

export default Page;
