import React, { Fragment, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { ButtonGroup } from "primereact/buttongroup";
import { InputText } from "primereact/inputtext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import api from "../../util/axiosConfig";

const PaymentMethodPage = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Assuming the edit page will have an ID parameter
  const [form, setForm] = useState([
    { nama: "", langkah: [{ langkah_ke: 1, deskripsi: "" }] },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (id) {
      // Fetch existing data for editing
      api.get(`/transaksi/payment/method/${id}/cara`).then((response) => {
        const data = response.data.data;
        setForm(
          data || [{ nama: "", langkah: [{ langkah_ke: 1, deskripsi: "" }] }]
        );
      });
    }
  }, [id]);

  const handleAddMethod = () => {
    setForm([
      ...form,
      { nama: "", langkah: [{ langkah_ke: 1, deskripsi: "" }] },
    ]);
  };

  const handleDeleteMethod = (methodIndex) => {
    setForm(form.filter((_, index) => index !== methodIndex));
  };

  const handleAddStep = (methodIndex) => {
    const newForm = [...form];
    newForm[methodIndex].langkah.push({
      langkah_ke: newForm[methodIndex].langkah.length + 1,
      deskripsi: "",
    });
    setForm(newForm);
  };

  const handleDeleteStep = (methodIndex, stepIndex) => {
    const newForm = [...form];
    if (newForm[methodIndex].langkah.length > 1) {
      newForm[methodIndex].langkah = newForm[methodIndex].langkah.filter(
        (_, index) => index !== stepIndex
      );
      setForm(newForm);
    }
  };

  const handleMethodChange = (methodIndex, field, value) => {
    const newForm = [...form];
    newForm[methodIndex][field] = value;
    setForm(newForm);
  };

  const handleStepChange = (methodIndex, stepIndex, field, value) => {
    const newForm = [...form];
    newForm[methodIndex].langkah[stepIndex][field] = value;
    setForm(newForm);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const dataToSend = form;

    if (id) {
      api
        .put(`/transaksi/payment/method/${id}/cara-bayar`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          navigate("/transaksi/payment/method"); // Navigate to success page after edit
        });
    }
  };

  return (
    <div className="w-full">
      <form onSubmit={onSubmit}>
        {form.map((method, methodIndex) => (
          <div key={methodIndex} className="flex flex-column gap-4 mb-4">
            <div className="flex flex-column gap-2">
              <label htmlFor={`nama-${methodIndex}`}>Nama Metode</label>
              <InputText
                id={`nama-${methodIndex}`}
                value={method.nama}
                onChange={(e) =>
                  handleMethodChange(methodIndex, "nama", e.target.value)
                }
                aria-describedby={`nama-${methodIndex}-help`}
              />
            </div>
            <div className="flex flex-column gap-2">
              <label>Langkah-langkah</label>
              {method.langkah.map((step, stepIndex) => (
                <Fragment key={stepIndex}>
                  <div className="flex flex-row gap-2 align-items-center">
                    <InputText
                      value={step.langkah_ke}
                      onChange={(e) =>
                        handleStepChange(
                          methodIndex,
                          stepIndex,
                          "langkah_ke",
                          e.target.value
                        )
                      }
                      aria-describedby={`langkah_ke-${methodIndex}-${stepIndex}-help`}
                      placeholder={`Langkah ke-${stepIndex + 1}`}
                      disabled
                    />
                    <InputText
                      value={step.deskripsi}
                      onChange={(e) =>
                        handleStepChange(
                          methodIndex,
                          stepIndex,
                          "deskripsi",
                          e.target.value
                        )
                      }
                      aria-describedby={`deskripsi-${methodIndex}-${stepIndex}-help`}
                      placeholder="Deskripsi"
                    />
                    <ButtonGroup>
                      {stepIndex === method.langkah.length - 1 && (
                        <Button
                          label="Add Step"
                          icon="pi pi-plus"
                          onClick={() => handleAddStep(methodIndex)}
                        />
                      )}
                      <Button
                        label="Delete"
                        icon="pi pi-trash"
                        onClick={() => handleDeleteStep(methodIndex, stepIndex)}
                        disabled={method.langkah.length === 1}
                      />
                    </ButtonGroup>
                  </div>
                </Fragment>
              ))}
            </div>
            <Button
              label="Delete Method"
              icon="pi pi-trash"
              onClick={() => handleDeleteMethod(methodIndex)}
              disabled={form.length === 1}
            />
          </div>
        ))}
        <Button type="submit" label="Submit" className="mr-2" />
        <Button
          type="button"
          label="Add Method"
          icon="pi pi-plus"
          onClick={handleAddMethod}
        />
      </form>
      {/* <div className="mt-4">
        <h2>Existing Payment Methods</h2>
        {data.map((item, index) => (
          <div key={index} className="mb-4">
            <h3>{item.nama}</h3>
            {item.langkah.map((step, stepIndex) => (
              <p key={stepIndex}>
                {step.langkah_ke}. {step.deskripsi}
              </p>
            ))}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default PaymentMethodPage;
