import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
  const token = localStorage.getItem("jwtToken");
  const token_admin = localStorage.getItem("jwtToken_admin");
  const isAuthenticated = token ? true : false;
  const isAuthenticatedAdmin = token_admin ? true : false;
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  } else if (isAuthenticatedAdmin) {
    return <Navigate to="/admin" />;
  } else {
    return <Outlet />;
  }
  // return isAuthenticated ? <Navigate to="/dashboard" /> : <Outlet />;
};

export default PublicRoute;
