import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { classNames } from "primereact/utils";
import { useQuery } from "react-query";
import { Card } from "primereact/card";
import api from "../../util/axiosConfig";
import { toast } from "react-toastify";
import "primeflex/primeflex.css";

const genderOptions = [
  { label: "Laki-laki", value: "Laki-laki" },
  { label: "Perempuan", value: "Perempuan" },
];

const EditProfile = () => {
  const validationSchema = Yup.object().shape({
    nama_lengkap: Yup.string().required("Nama is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    jenis_kelamin: Yup.string().required("Jenis Kelamin is required"),
    tanggal_lahir: Yup.date().required("Tanggal Lahir is required").nullable(),
    ktp: Yup.string().required("KTP is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    data: profileData,
    isLoading,
    isError,
  } = useQuery("profile", async () => {
    const response = await api.get("/auth/myprofile");
    return response.data.data;
  });

  useEffect(() => {
    if (profileData) {
      setValue("nama_lengkap", profileData.nama_lengkap);
      setValue("email", profileData.email);
      setValue("phone", profileData.phone);
      setValue("jenis_kelamin", profileData.jenis_kelamin);
      setValue("ktp", profileData.ktp);
      setValue("tanggal_lahir", new Date(profileData.tanggal_lahir));
    }
  }, [profileData, setValue]);

  const onSubmit = async (data) => {
    try {
      await api.post("/auth/edit-profile", data);
      toast.success("Profile updated successfully");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error while updating profile");
      }
    }
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading profile data</div>;
  }

  return (
    <div className="p-d-flex p-jc-center p-ai-center p-mt-4">
      <Card
        title="Edit Profile"
        className="p-shadow-5"
        style={{ width: "400px" }}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="p-field p-mb-4">
            <label htmlFor="nama">Nama</label>
            <Controller
              name="nama_lengkap"
              control={control}
              render={({ field }) => (
                <InputText
                  id="nama"
                  {...field}
                  className={classNames({ "p-invalid": errors.nama_lengkap })}
                />
              )}
            />
            {getFormErrorMessage("nama_lengkap")}
          </div>
          <div className="p-field p-mb-4">
            <label htmlFor="email">Email</label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputText
                  id="email"
                  {...field}
                  className={classNames({ "p-invalid": errors.email })}
                />
              )}
            />
            {getFormErrorMessage("email")}
          </div>
          <div className="p-field p-mb-4">
            <label htmlFor="phone">Phone</label>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  defaultCountry="ID"
                  international
                  countryCallingCodeEditable={false}
                  value={field.value}
                  onChange={(phone) => setValue("phone", phone)}
                  inputComponent={InputText}
                  className={classNames({ "p-invalid": errors.phone })}
                />
              )}
            />
            {getFormErrorMessage("phone")}
          </div>
          <div className="p-field p-mb-4">
            <label htmlFor="jenis_kelamin">Jenis Kelamin</label>
            <Controller
              name="jenis_kelamin"
              control={control}
              render={({ field }) => (
                <Dropdown
                  id="jenis_kelamin"
                  {...field}
                  options={genderOptions}
                  placeholder="Pilih Jenis Kelamin"
                  className={classNames({ "p-invalid": errors.jenis_kelamin })}
                />
              )}
            />
            {getFormErrorMessage("jenis_kelamin")}
          </div>
          <div className="p-field p-mb-4">
            <label htmlFor="tanggal_lahir">Tanggal Lahir</label>
            <Controller
              name="tanggal_lahir"
              control={control}
              render={({ field }) => (
                <Calendar
                  id="tanggal_lahir"
                  {...field}
                  dateFormat="yy-mm-dd"
                  showIcon
                  className={classNames({ "p-invalid": errors.tanggal_lahir })}
                />
              )}
            />
            {getFormErrorMessage("tanggal_lahir")}
          </div>
          <div className="p-field p-mb-4">
            <label htmlFor="ktp">KTP</label>
            <Controller
              name="ktp"
              control={control}
              render={({ field }) => (
                <InputText
                  id="ktp"
                  {...field}
                  className={classNames({ "p-invalid": errors.ktp })}
                />
              )}
            />
            {getFormErrorMessage("ktp")}
          </div>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-save"
            className="p-mt-2"
          />
        </form>
      </Card>
    </div>
  );
};

export default EditProfile;
