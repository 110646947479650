import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { useQuery } from "react-query";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import api from "../../util/axiosConfig";
import PieChart from "../../chart/PieChart";
import BarChart from "../../chart/BarChart";

const fetchAggregatedData = async ({ queryKey }) => {
  const [, { startDate, endDate }] = queryKey;
  const response = await api.get("/report/aggregated", {
    params: {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    },
  });
  return response.data;
};

const Page = () => {
  const [startDate, setStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("month").toDate());
  const toast = useRef(null);

  const { data, error, isLoading } = useQuery(
    ["aggregatedData", { startDate, endDate }],
    fetchAggregatedData,
    {
      keepPreviousData: true,
    }
  );

  if (isLoading) {
    return (
      <div
        className="p-d-flex p-jc-center p-ai-center"
        style={{ height: "100vh" }}
      >
        <ProgressSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="p-d-flex p-jc-center p-ai-center"
        style={{ height: "100vh" }}
      >
        <div>Error: {error.message}</div>
      </div>
    );
  }

  const totalStatus = data.totalStatus;

  const months = Object.keys(data.aggregatedData);
  const barStatus = {
    dikirim: months.map((month) => data.aggregatedData[month].dikirim || 0),
    terkirim: months.map((month) => data.aggregatedData[month].terkirim || 0),
    dibaca: months.map((month) => data.aggregatedData[month].dibaca || 0),
    failed: months.map((month) => data.aggregatedData[month].failed || 0),
  };

  const downloadExcel = async () => {
    try {
      const response = await api.post(
        "/report/download",
        {
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
        },
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `blast_details_${moment(startDate).format("YYYYMMDD")}_${moment(
          endDate
        ).format("YYYYMMDD")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading Excel file", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error downloading Excel file",
        life: 3000,
      });
    }
  };

  return (
    <div className="p-d-flex p-flex-column p-ai-center p-p-4">
      <Toast ref={toast} />
      <Card className="p-shadow-5" style={{ width: "100%", maxWidth: "800px" }}>
        <h1>Blast Details Charts</h1>
        <div className="p-d-flex p-ai-center p-jc-center p-mb-4 p-flex-wrap">
          <label className="p-mr-2">Start Date: </label>
          <Calendar
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            showIcon
            className="p-mr-2"
          />
          <label className="p-mx-2">End Date: </label>
          <Calendar
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            showIcon
            className="p-mr-2"
          />
        </div>
        <div className="p-d-flex p-flex-column p-ai-center p-mb-4">
          <div style={{ width: "500px" }}>
            <PieChart width data={totalStatus} />
          </div>
          <br></br>
          <BarChart data={{ months, ...barStatus }} />
        </div>
        <div className="p-d-flex p-jc-center">
          <Button
            label="Download Excel"
            icon="pi pi-file-excel"
            className="p-button-success"
            onClick={downloadExcel}
          />
        </div>
      </Card>
    </div>
  );
};

export default Page;
