import React, { useState } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableDinamis from "../../../util/dynamic/tableDinamis";
import api from "../../../util/axiosConfig";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";

const Page = () => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(0);

  const handleDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to delete this item?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await api.delete(`/konten/${id}`);
          toast.success("Data deleted successfully!");
          setRefresh(refresh + 1);
        } catch (error) {
          toast.error("An error occurred while deleting data.");
        }
      },
      reject: () => {
        toast.info("Delete action was canceled.");
      },
    });
  };

  return (
    <div className="w-full">
      <TableDinamis
        enableCaching={false}
        title="Konten"
        headerComponents={[
          () => (
            <Button
              label="Add Konten"
              icon="pi pi-plus"
              onClick={() => navigate("/konten/form")}
              className="btn-core mr-2"
            />
          ),
        ]}
        id="konten"
        path="/konten"
        onRefresh={refresh}
        kolom={[
          {
            field: "file.url_file",
            label: "Image",
            type: "custom",
            component: (rowData) => (
              <Image
                src={rowData.file.url_file}
                zoomSrc={rowData.file.url_file}
                alt="Image"
                width="80"
                height="60"
                preview
              />
            ),
            sort: true,
          },
          {
            field: "path",
            label: "Path",
            sort: true,
          },
          {
            field: "title",
            label: "Title",
            sort: true,
          },
        ]}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Edit"
              className="mr-2 font-bold btn-succes-outlined"
              severity="success"
              outlined
              onClick={() => navigate(`/konten/form/${rowData.id}`)}
            />
            <Button
              label="Delete"
              className="mr-2 font-bold btn-danger-outlined"
              severity="danger"
              outlined
              onClick={() => handleDelete(rowData.id)}
            />
          </div>
        )}
      />

      <ConfirmDialog />
    </div>
  );
};

export default Page;
