import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import api from "../../../util/axiosConfig";
import CustomDropzone from "../../../util/CustomDropzone";
import FormSpec from "../../../util/dynamic/formSpec";

const Page = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priceId, setPriceId] = useState(null);
  const [seoId, setSeoId] = useState(null);
  const [specId, setSpecId] = useState(null);
  const [isPackage, setIsPackage] = useState(false);
  const [specOptions, setSpecOptions] = useState([]);
  const [seoOptions, setSeoOptions] = useState([]);
  const [priceOptions, setPriceOptions] = useState([]);
  const [details, setDetails] = useState([]);
  const [titleSpec, setTitleSpec] = useState("");
  console.log(details);
  useEffect(() => {
    fetchSpecData();
    fetchSeoData();
    fetchPriceData();

    if (id) {
      fetchPaketData(id);
    }
  }, [id]);

  const transformDetails = (details) => {
    return details.map((item) => {
      const type = item.type;
      const typeObject = item[type];
      const idKey = `${type}_id`;
      const detailId = typeObject && typeObject.id ? typeObject.id : item.id;
      const title =
        typeObject && typeObject.title
          ? typeObject.title
          : `${
              type.charAt(0).toUpperCase() + type.slice(1)
            } Kalo tidak ada Fielnye`;
      return {
        [idKey]: detailId,
        title: title,
        type: type,
      };
    });
  };

  const fetchPaketData = async (id) => {
    try {
      const response = await api.get(`/paket/${id}`);
      const data = response.data.data;
      console.log(data.cover_id);
      console.log("hahah");
      setTitle(data.title);
      setDescription(data.description);
      setPriceId(data.price_id);
      // setSeoId(data.seo_id);
      setSpecId(data.spec_id);
      // setUploadedFile(data.cover_id);
      setIsPackage(data.isPackage);
      setUploadedFile(data.cover_id);
      // setTitleSpec(data.specificationDetails.title);
      const transformedDetails = transformDetails(data.spec.details); // Transform the details
      setDetails(transformedDetails);
    } catch (error) {
      console.error("Error fetching paket data:", error);
    }
  };

  const fetchSpecData = async () => {
    try {
      const response = await api.get("/spec");
      const formattedOptions = response.data.data.map((item) => ({
        name: item.title,
        value: item.id,
      }));
      setSpecOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching spec data:", error);
    }
  };

  const fetchSeoData = async () => {
    try {
      const response = await api.get("/seo");
      const formattedOptions = response.data.data.map((item) => ({
        name: item.title,
        value: item.id,
      }));
      setSeoOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching seo data:", error);
    }
  };

  const fetchPriceData = async () => {
    try {
      const response = await api.get("/harga");
      const formattedOptions = response.data.data.map((item) => ({
        name: item.title,
        value: item.id,
      }));
      setPriceOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching price data:", error);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsPackage(e.checked);
  };

  const formData = {
    specId: specId,
    isPackage: isPackage,
    cover_id: uploadedFile,
    packageDetails: {
      // seo_id: seoId,
      price_id: priceId,
      title: title,
      description: description,
    },
    specificationDetails: {
      title: titleSpec,
      details,
    },
  };

  const handleSubmit = async () => {
    try {
      if (id) {
        await api.put(`/paket/${id}`, formData);
        toast.success("Paket berhasil diperbarui!");
      } else {
        await api.post("/paket", formData);
        toast.success("Paket berhasil ditambahkan!");
      }
      navigate("/paket");
    } catch (error) {
      toast.error("Gagal menyimpan data!");
      console.error("Error saving paket:", error);
    }
  };

  const handleFileUploaded = (fileData) => {
    console.log("File uploaded:", fileData);
    setUploadedFile(fileData.id);
  };

  return (
    <div className="card-paket costume-form costume-form-paket ">
      <div className="text-2xl font-bold mb-4">
        {id ? "Edit Paket" : "Tambah Paket"}
      </div>

      <div className="grid-paket1">
        <div>
          <div className="title-input">Title</div>
          <InputText value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div>
          <div className="title-input">Price ID</div>
          <Dropdown
            value={priceId}
            onChange={(e) => setPriceId(e.value)}
            options={priceOptions}
            optionLabel="name"
            placeholder="Select a Price"
            className="w-full md:w-14rem"
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="title-input">Cover Paket</div>
        <CustomDropzone
          onFileUploaded={handleFileUploaded}
          initialFileId={uploadedFile}
        />
      </div>
      <div className="mt-4">
        <div className="title-input">Description</div>
        <InputTextarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={5}
          cols={30}
        />
      </div>

      {/* <div>
        <div>SEO ID</div>
        <Dropdown
          value={seoId}
          onChange={(e) => setSeoId(e.value)}
          options={seoOptions}
          optionLabel="name"
          placeholder="Select SEO"
          className="w-full md:w-14rem"
        />
      </div> */}
      {!isPackage && (
        <div>
          <div>Spec ID</div>
          <Dropdown
            value={specId}
            onChange={(e) => setSpecId(e.value)}
            options={specOptions}
            optionLabel="name"
            placeholder="Select Spec"
            className="w-full md:w-14rem"
          />
        </div>
      )}
      <div>
        <div>Is Package</div>
        <Checkbox
          onChange={handleCheckboxChange}
          checked={isPackage}></Checkbox>
      </div>
      <Divider className="py-4"></Divider>
      {isPackage && (
        <FormSpec
          titleSpec={titleSpec}
          setTitleSpec={setTitleSpec}
          details={details}
          setDetails={setDetails}
        />
      )}
      <Button className="mt-6" onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default Page;
