import React, { useState } from "react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalForm from "../../util/dynamic/formDinamis";
import TableDinamis from "../../util/dynamic/tableDinamis";
import api from "../../util/axiosConfig";
import { useNavigate } from "react-router-dom";

const Page = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectID, setSelectID] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [refresh, setRefresh] = useState(0);

  // Fungsi untuk menangani penambahan item baru
  const handleAdd = () => {
    setIsEditMode(false);
    setFormData({
      image_id: {
        label: "Bank Logo",
        value: "",
        type: "upload_filemanager",
      },
      bank_name: {
        label: "Bank Name",
        value: "",
        type: "text",
        validation: {
          required: true,
        },
      },
      account_name: {
        label: "Account Name",
        value: "",
        type: "text",
        validation: {
          required: true,
        },
      },
      norek: {
        label: "No. Rek",
        value: "",
        type: "number",
        validation: {
          required: true,
        },
      },
      type: {
        label: "Type Payment",
        value: "",
        type: "dropdown",
        options: [
          { label: "Transfer Manual", value: "transfer manual" },
          { label: "Virtual Account", value: "virtual account" },
        ],
        validation: {
          required: true,
        },
      },
      status: {
        label: "Status",
        value: "",
        type: "dropdown",
        options: [
          { label: "Aktif", value: "true" },
          { label: "Tidak", value: "false" },
        ],
        validation: {
          required: true,
        },
      },
    });
    setShowModal(true);
  };

  // Fungsi untuk menangani pengeditan item yang ada
  const handleEdit = async (id) => {
    setSelectID(id);
    const result = await api.get(`/transaksi/payment/method/${id}`);
    setIsEditMode(true);
    setFormData({
      image_id: {
        label: "Bank Logo",
        value: result.data.data.image_id,
        type: "upload_filemanager",
      },
      bank_name: {
        label: "Bank Name",
        value: result.data.data.bank_name,
        type: "text",
        validation: {
          required: true,
        },
      },
      account_name: {
        label: "Account Name",
        value: result.data.data.account_name,
        type: "text",
        validation: {
          required: true,
        },
      },
      norek: {
        label: "No. Rek",
        value: result.data.data.norek,
        type: "number",
        validation: {
          required: true,
        },
      },
      type: {
        label: "Type Payment",
        value: result.data.data.type,
        type: "dropdown",
        options: [
          { label: "Transfer Manual", value: "transfer manual" },
          { label: "Virtual Account", value: "virtual account" },
        ],
        validation: {
          required: true,
        },
      },
      status: {
        label: "Status",
        value: result.data.data.status.toString(),
        type: "dropdown",
        options: [
          { label: "Aktif", value: "true" },
          { label: "Tidak", value: "false" },
        ],
        validation: {
          required: true,
        },
      },
    });
    setShowModal(true);
  };

  // Fungsi untuk menangani penyimpanan data
  const handleSave = async (data) => {
    try {
      if (!isEditMode) {
        await api.post(`/transaksi/payment/method`, data);
      } else {
        await api.put(`/transaksi/payment/method/${selectID}`, data);
      }
      toast.success("Data saved successfully!");
      setRefresh(refresh + 1);
      setShowModal(false);
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while saving data.");
      }
    }
  };

  // Fungsi untuk menangani penghapusan item
  const handleDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to delete this item?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await api.delete(`/transaksi/payment/method/${id}`);
          toast.success("Data deleted successfully!");
          setRefresh(refresh + 1);
        } catch (error) {
          toast.error("An error occurred while deleting data.");
        }
      },
      reject: () => {
        toast.info("Delete action was canceled.");
      },
    });
  };

  return (
    <div className="w-full">
      {showModal && (
        <ModalForm
          visible={showModal}
          onHide={() => setShowModal(false)}
          onSave={handleSave}
          formData={formData}
          isEditMode={isEditMode}
        />
      )}

      <TableDinamis
        title="Payment Metode"
        headerComponents={[
          () => (
            <Button
              label="Add Metode"
              icon="pi pi-plus"
              onClick={handleAdd}
              className="btn-core mr-2"
            />
          ),
        ]}
        id="hotel"
        path="/transaksi/payment/method"
        onRefresh={refresh}
        kolom={[
          {
            field: "file.url_file",
            label: "Image",
            type: "custom",
            component: (rowData) => (
              <Image
                src={rowData.file.url_file}
                zoomSrc={rowData.file.url_file}
                alt="Image"
                width="80"
                height="60"
                preview
              />
            ),
            sort: true,
          },
          {
            field: "bank_name",
            label: "Bank Name",
            sort: true,
          },
          {
            field: "account_name",
            label: "Account Name",
            sort: true,
          },
          {
            field: "norek",
            label: "No. Rek",
            sort: true,
          },
        ]}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Edit"
              className="mr-2 font-bold btn-succes-outlined"
              severity="success"
              outlined
              onClick={() => handleEdit(rowData.id)}
            />
            <Button
              label="Setup Payment Method"
              className="mr-2 font-bold btn-succes-outlined"
              severity="success"
              outlined
              onClick={() =>
                navigate(`/transaksi/payment/method/form/${rowData.id}`)
              }
            />
            <Button
              label="Delete"
              className="mr-2 font-bold btn-danger-outlined"
              severity="danger"
              outlined
              onClick={() => handleDelete(rowData.id)}
            />
          </div>
        )}
      />

      <ConfirmDialog />
    </div>
  );
};

export default Page;
