import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { DataView } from "primereact/dataview";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useDebounce } from "use-debounce";
import moment from "moment";
import api from "../axiosConfig";
import "primeflex/primeflex.css";
import "./TableDinamis.css"; // Custom CSS file for additional styling

const fetchTableData = async ({ queryKey }) => {
  const [, { path, page, pageSize, sortField, sortOrder, search }] = queryKey;
  const response = await api.get(
    `${path}?page=${page}&size=${pageSize}&sortField=${sortField}&order=${sortOrder}&search=${encodeURIComponent(
      search
    )}`
  );
  return response.data;
};

const DataViewComponent = ({
  id,
  path,
  kolom,
  initialPageSize = 10,
  actionButtonTemplate,
  onRefresh,
  enableCaching = true,
  title = "",
  headerComponents = [],
}) => {
  const [page, setPage] = React.useState(
    parseInt(sessionStorage.getItem(`${id}-tablePage`) || 1)
  );
  const [pageSize, setPageSize] = React.useState(
    parseInt(sessionStorage.getItem(`${id}-tablePageSize`) || initialPageSize)
  );
  const [sortField, setSortField] = React.useState(
    sessionStorage.getItem(`${id}-tableSortField`) || ""
  );
  const [sortOrder, setSortOrder] = React.useState(
    parseInt(sessionStorage.getItem(`${id}-tableSortOrder`) || 1)
  );
  const [search, setSearch] = React.useState(
    sessionStorage.getItem(`${id}-tableSearch`) || ""
  );
  const [debouncedSearch] = useDebounce(search, 800);

  React.useEffect(() => {
    sessionStorage.setItem(`${id}-tablePage`, page.toString());
    sessionStorage.setItem(`${id}-tablePageSize`, pageSize.toString());
    sessionStorage.setItem(`${id}-tableSortField`, sortField);
    sessionStorage.setItem(`${id}-tableSortOrder`, sortOrder.toString());
    sessionStorage.setItem(`${id}-tableSearch`, search);
  }, [page, pageSize, sortField, sortOrder, search]);

  const queryClient = useQueryClient();

  const { data, isLoading, isError, error } = useQuery(
    [
      id,
      { path, page, pageSize, sortField, sortOrder, search: debouncedSearch },
    ],
    fetchTableData,
    {
      keepPreviousData: true,
      staleTime: enableCaching ? 1000 * 60 * 5 : 0,
      cacheTime: enableCaching ? 1000 * 60 * 10 : 0,
      onSuccess: () => console.log("Data fetched successfully"),
    }
  );

  React.useEffect(() => {
    if (onRefresh > 0) {
      queryClient.invalidateQueries(id);
    }
  }, [onRefresh, queryClient, id]);

  const handlePageChange = (e) => {
    setPage(e.page + 1);
  };

  const handleSort = (e) => {
    setSortField(e.value);
    setSortOrder(sortOrder);
    setPage(1);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.value);
    setPage(1);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.value);
    setPage(1);
  };

  const clearSearch = () => {
    setSearch("");
    setPage(1);
    queryClient.invalidateQueries(id);
  };

  const handleRefresh = () => {
    queryClient.invalidateQueries(id);
  };

  const formatDate = (value, format) => moment(value).format(format);
  const formatCurrency = (value) => `Rp ${value.toLocaleString("id-ID")}`;
  const formatImage = (value) => (
    <img src={value} alt="Image" className="item-image" />
  );
  const formatPdf = (value) => (
    <a href={value} target="_blank" rel="noopener noreferrer">
      Lihat PDF
    </a>
  );
  const formatLink = (value, rowData) => (
    <a href={rowData.url} target="_blank" rel="noopener noreferrer">
      {value}
    </a>
  );

  const dynamicColumns = kolom.map((col, i) => {
    let bodyTemplate = null;

    if (col.type === "custom" && col.component) {
      bodyTemplate = col.component;
    } else {
      switch (col.type) {
        case "date":
          bodyTemplate = (rowData) =>
            formatDate(rowData[col.field], col.format || "DD/MM/YYYY");
          break;
        case "currency":
          bodyTemplate = (rowData) => formatCurrency(rowData[col.field]);
          break;
        case "image":
          bodyTemplate = (rowData) => formatImage(rowData[col.field]);
          break;
        case "pdf":
          bodyTemplate = (rowData) => formatPdf(rowData[col.field]);
          break;
        case "linkto":
          bodyTemplate = (rowData) => formatLink(rowData[col.field], rowData);
          break;
        default:
          bodyTemplate = null;
      }
    }

    return {
      field: col.field,
      header: col.label,
      body: bodyTemplate,
      sortable: col.sort,
    };
  });

  const sortOptions = kolom
    .filter((col) => col.sort)
    .map((col) => ({ label: col.label, value: col.field }));

  const sortOrderOptions = [
    { label: "Ascending", value: 1 },
    { label: "Descending", value: -1 },
  ];

  const pageSizeOptions = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
  ];

  const renderHeader = () => {
    return (
      <div className="table-header">
        <div className="header-left">
          <Button
            icon="pi pi-refresh"
            onClick={handleRefresh}
            className="p-button-rounded p-button-text"
          />
          {title ? <h2>{title}</h2> : null}
          <div className="p-inputgroup">
            <InputText
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
              placeholder="Cari..."
            />
            <Button icon="pi pi-search-minus" onClick={clearSearch} />
          </div>
        </div>
        <div className="header-right">
          <Dropdown
            value={sortField}
            options={sortOptions}
            onChange={handleSort}
            placeholder="Sort by"
            className="p-mr-2"
          />
          <Dropdown
            value={sortOrder}
            options={sortOrderOptions}
            onChange={handleSortOrderChange}
            placeholder="Sort order"
          />
          {headerComponents.map((Component, index) => (
            <Component key={index} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="datatable-container">
      <DataView
        value={data?.data || []}
        layout="list"
        lazy
        loading={isLoading}
        itemTemplate={(item) => (
          <div className="p-col-12 p-md-4">
            <div className="product-grid-item card">
              {dynamicColumns.map((col, i) => (
                <div key={i} className="p-grid p-nogutter">
                  <div className="p-col-6">
                    <strong>{col.header}: </strong>
                  </div>
                  <div className="p-col-6">
                    {col.body ? col.body(item) : item[col.field]}
                  </div>
                </div>
              ))}
              <div className="p-grid p-nogutter">
                <div className="p-col-12">
                  {actionButtonTemplate ? actionButtonTemplate(item) : null}
                </div>
              </div>
            </div>
          </div>
        )}
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Menampilkan {first} sampai {last} dari {totalRecords} entri"
        rows={pageSize}
        totalRecords={data?.totalItems}
        first={(page - 1) * pageSize}
        onPage={(e) => {
          setPage(e.page + 1);
        }}
        header={renderHeader()}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={(e) => handleSort(e)}
        className="p-dataview-gridlines p-dataview-responsive"
      />
      <div className="table-footer">
        <Dropdown
          value={pageSize}
          options={pageSizeOptions}
          onChange={handlePageSizeChange}
          placeholder="Rows per page"
          className="page-size-dropdown"
        />
      </div>
      {isError && <p>Error: {error.message}</p>}
    </div>
  );
};

export default DataViewComponent;
