import React from "react";
import { useMediaQuery } from "react-responsive";
import DataTableComponent from "./DataTableComponent";
import DataViewComponent from "./DataViewComponent";

const TableDinamis = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return isMobile ? (
    <DataViewComponent {...props} />
  ) : (
    <DataTableComponent {...props} />
  );
};

export default TableDinamis;
