import React, { useState } from "react";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";

const MenubarComponent = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const items = [
    {
      label: "Jamaah",
      icon: "pi pi-fw pi-user",
      command: () => navigate("/jamaah"),
    },
    {
      label: "Paket",
      icon: "pi pi-fw pi-box",
      command: () => navigate("/paket"),
    },
    {
      label: "Jadwal",
      icon: "pi pi-fw pi-calendar",
      command: () => navigate("/jadwal"),
    },
    {
      label: "Master Data",
      icon: "pi pi-fw pi-list-check",
      // command: () => navigate("/master"),
      items: [
        {
          label: "Hotel",
          icon: "pi pi-fw pi-building",
          command: () => navigate("master/hotel"),
        },
        {
          label: "Maskapai",
          icon: "pi pi-fw pi-send",
          command: () => navigate("master/maskapai"),
        },
        {
          label: "Transport",
          icon: "pi pi-fw pi-car",
          command: () => navigate("master/transport"),
        },
        {
          label: "Perlengkapan",
          icon: "pi pi-fw pi-shopping-bag",
          command: () => navigate("master/perlengkapan"),
        },
        {
          label: "Intenerary",
          icon: "pi pi-fw pi-file",
          command: () => navigate("master/itinerary"),
        },
        {
          label: "Feature",
          icon: "pi pi-fw pi-file",
          command: () => navigate("master/feature"),
        },
        {
          label: "Condition",
          icon: "pi pi-fw pi-file",
          command: () => navigate("master/condition"),
        },
      ],
    },
    {
      label: "Transaksi",
      icon: "pi pi-fw pi-money-bill",
      command: () => navigate("/transaksi"),
    },
    {
      label: "Website",
      icon: "pi pi-fw pi-list-check",
      // command: () => navigate("/website"),
      items: [
        {
          label: "Artikel",
          icon: "pi pi-fw pi-file",
          command: () => navigate("website/artikel"),
        },
        {
          label: "Gallery",
          icon: "pi pi-fw pi-images",
          command: () => navigate("website/galeri"),
        },
        {
          label: "Slider",
          icon: "pi pi-fw pi-image",
          command: () => navigate("website/slider"),
        },
        {
          label: "Testimoni",
          icon: "pi pi-fw pi-send",
          command: () => navigate("website/testimoni"),
        },
        {
          label: "Mitra",
          icon: "pi pi-fw pi-send",
          command: () => navigate("website/mitra"),
        },
        {
          label: "Konten",
          icon: "pi pi-fw pi-user",
          command: () => navigate("/konten"),
        },
        // {
        //   label: "Transport",
        //   icon: "pi pi-fw pi-car",
        //   command: () => navigate("website/transport"),
        // },
        // {
        //   label: "Perlengkapan",
        //   icon: "pi pi-fw pi-palette",
        //   command: () => navigate("website/perlengkapan"),
        // },
      ],
    },
    {
      label: "User",
      icon: "pi pi-fw pi-user",
      command: () => navigate("/user"),
    },
    // Tambahkan item menu lain jika diperlukan
  ];

  const showLogoutDialog = () => {
    setVisible(true);
  };

  const hideLogoutDialog = () => {
    setVisible(false);
  };

  const logout = () => {
    setVisible(false);
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  const end = (
    <div>
      <Button
        icon="pi pi-fw pi-cog"
        className="p-button-outlined mr-2"
        onClick={() => navigate("/settings")}
      />
      <Button
        label="Logout"
        icon="pi pi-fw pi-sign-out"
        className="mr-2 btn-danger-outlined"
        severity="danger"
        outlined
        onClick={showLogoutDialog}
      />
    </div>
  );

  return (
    <div className="menubar-container ">
      <Menubar className="font-semibold text-black" model={items} end={end} />
      <Dialog
        header="Konfirmasi Logout"
        visible={visible}
        style={{ width: "350px" }}
        footer={
          <div>
            <Button
              label="Tidak"
              icon="pi pi-times"
              onClick={hideLogoutDialog}
              className="p-button-text"
            />
            <Button label="Ya" icon="pi pi-check" onClick={logout} autoFocus />
          </div>
        }
        onHide={hideLogoutDialog}>
        <p>Apakah Anda yakin ingin logout?</p>
      </Dialog>
      <style jsx>{`
        .menubar-container {
          width: 100%;
          position: sticky;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 30;
          background-color: #f5f5f5;
          border-bottom: 1px solid #ddd;
        }
        .p-menubar {
          padding-right: 30px;
          padding-left: 30px;
          background-color: #fff;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }
        .p-button-outlined {
          color: var(--primary-color);
          border-color: var(--primary-color);
        }
        .p-button-outlined:hover {
          background-color: var(--primary-color);
          color: #fff;
        }
      `}</style>
    </div>
  );
};

export default MenubarComponent;
