import React, { Fragment, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { ButtonGroup } from "primereact/buttongroup";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../util/axiosConfig";
import "react-toastify/dist/ReactToastify.css";
import CustomDropzone from "../../../util/CustomDropzone";

const Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [uploadedFile, setUploadedFile] = useState(null);
  //   const [uploadedIcons, setUploadedIcons] = useState({});
  console.log(uploadedFile);

  const [form, setForm] = useState({
    image_id: null,
    path: "",
    title: "",
    description: "",
    data: [{ title: "", description: "", icon: "" }],
  });
  console.log(form);
  console.log(">>>>>");

  const pathOptions = [
    { label: "Mitra", value: "mitra" },
    { label: "Tentang Kami", value: "tentang_kami" },
    { label: "Data Benefit", value: "data_benefit" },
    { label: "Data Perjalanan", value: "data_perjalanan" },
  ];

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      image_id: uploadedFile,
    }));
  }, [uploadedFile]);

  useEffect(() => {
    if (id) {
      api.get(`/konten/${id}`).then((response) => {
        const { image_id, path, title, description, data } = response.data.data;

        // const fetchImageUrls = async () => {
        //   let imageUrl = null;
        //   if (image_id) {
        //     const imageResponse = await api.post(`/filemanager/files/find`, {
        //       file: image_id,
        //     });
        //     console.log("haha", imageResponse.data.data.url_file);
        //     imageUrl = imageResponse.data.data.url_file;
        //   }

        //   const iconUrls = {};
        //   for (let i = 0; i < data.length; i++) {
        //     if (data[i].icon) {
        //       const iconResponse = await api.post(`/filemanager/files/find`, {
        //         file: data[i].icon,
        //       });
        //       iconUrls[i] = iconResponse.data.data.url_file;
        //     }
        //   }

        //   setUploadedFile(imageUrl);
        //   setUploadedIcons(iconUrls);
        // };

        setForm({
          image_id: image_id || null,
          path: path || "",
          title: title || "",
          description: description || "",
          data: data || [{ title: "", description: "", icon: null }],
        });

        // fetchImageUrls();
      });
    }
  }, [id]);

  const handleAdd = () => {
    setForm((prevState) => ({
      ...prevState,
      data: [...prevState.data, { title: "", description: "", icon: "" }],
    }));
  };

  const handleDelete = (index) => {
    if (form.data.length > 1) {
      setForm((prevState) => ({
        ...prevState,
        data: prevState.data.filter((_, i) => i !== index),
      }));
    }
  };

  const handleChange = (index, field, value) => {
    const newData = [...form.data];
    newData[index] = { ...newData[index], [field]: value };
    setForm((prevState) => ({
      ...prevState,
      data: newData,
    }));
  };

  //   const handleTitleChange = (e) => {
  //     setForm((prevState) => ({
  //       ...prevState,
  //       title: e.target.value,
  //     }));
  //   };
  const handleFieldChange = (field, value) => {
    setForm((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (form.image_id === null) {
      alert("Please upload image");
      return;
    }
    const dataToSend = {
      image_id: form.image_id,
      path: form.path,
      title: form.title,
      description: form.description,
      data: form.data,
    };

    const endpoint = id ? `/konten/${id}` : "/konten";
    const method = id ? "put" : "post";

    api[method](endpoint, dataToSend, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      navigate("/konten"); // Navigate to success page
    });
  };

  const handleFileUploaded = (fileData) => {
    console.log("File uploaded:", fileData);
    setUploadedFile(fileData.id);
  };

  const handleIconUpload = (index, fileData) => {
    const newData = [...form.data];
    newData[index] = { ...newData[index], icon: fileData.url_file };
    setForm((prevState) => ({
      ...prevState,
      data: newData,
    }));

    // setUploadedIcons((prevIcons) => ({
    //   ...prevIcons,
    //   [index]: fileData.url_file,
    // }));
  };

  return (
    <div className="w-full">
      <form onSubmit={onSubmit}>
        <div className="flex flex-column gap-2">
          <label htmlFor="title">Avatar</label>
          <CustomDropzone
            onFileUploaded={handleFileUploaded}
            initialFileId={form.image_id}
          />
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="title">Path</label>
          {/* <InputText
            value={form.path}
            onChange={(e) => handleFieldChange("path", e.target.value)}
            aria-describedby="title-help"
          /> */}
          <Dropdown
            value={form.path}
            options={pathOptions}
            onChange={(e) => handleFieldChange("path", e.value)}
            placeholder="Select a Path"
          />
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="title">Title</label>
          <InputText
            value={form.title}
            // onChange={handleTitleChange}
            onChange={(e) => handleFieldChange("title", e.target.value)}
            aria-describedby="title-help"
          />
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="title">Description</label>
          <InputText
            value={form.description}
            onChange={(e) => handleFieldChange("description", e.target.value)}
            aria-describedby="title-help"
          />
        </div>
        {form.data.map((item, index) => (
          <div key={`data-${index}`} className="flex flex-column gap-2">
            <label htmlFor={`title-${index}`}>Item Title</label>
            <InputText
              value={item.title}
              onChange={(e) => handleChange(index, "title", e.target.value)}
              aria-describedby={`title-help-${index}`}
            />
            <label htmlFor={`description-${index}`}>Item Description</label>
            <InputText
              value={item.description}
              onChange={(e) =>
                handleChange(index, "description", e.target.value)
              }
              aria-describedby={`description-help-${index}`}
            />
            <label htmlFor={`icon-${index}`}>Item Icon asd</label>
            <CustomDropzone
              onFileUploaded={(fileData) => handleIconUpload(index, fileData)}
              initialFileId={item.icon}
            />
            <ButtonGroup>
              {index === form.data.length - 1 && (
                <Button label="Add" icon="pi pi-plus" onClick={handleAdd} />
              )}
              <Button
                label="Delete"
                icon="pi pi-trash"
                onClick={() => handleDelete(index)}
                disabled={form.data.length === 1}
              />
            </ButtonGroup>
          </div>
        ))}
        <Button type="submit" label="Submit" />
      </form>
    </div>
  );
};

export default Page;
