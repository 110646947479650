import { useNavigate } from "react-router-dom";
import TableDinamis from "../../util/dynamic/tableDinamis";
import moment from "moment";
import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const MitraPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <TableDinamis
        title="Mitra"
        id="mitra"
        path="/mitra"
        onRefresh={0}
        kolom={[
          {
            field: "fullname",
            label: "Nama Lengkap",
            sort: true,
          },
          {
            field: "phone",
            label: "Phone",
            sort: true,
          },
          {
            field: "email",
            label: "email",
            sort: true,
          },
          {
            field: "description",
            label: "description",
            sort: true,
          },
          {
            field: "createdAt",
            label: "Created At",
            type: "date",
            format: "DD MMM yyyy",
            sort: true,
          },
        ]}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Email"
              className="p-button-raised p-button-primary mr-2"
              onClick={() => window.open(`mailto:${rowData.email}`)}
            />
            <Button
              label="Whatsapp"
              className="p-button-raised p-button-primary mr-2"
              onClick={() => window.open(`https://wa.me/${rowData.phone}`)}
            />
          </div>
        )}
      />
    </div>
  );
};

export default MitraPage;
