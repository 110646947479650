import React from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { InputTextarea } from "primereact/inputtextarea";
import "primeflex/primeflex.css";
import TextEditor from "../TextEditor";
import CustomDropzone from "../CustomDropzone";
import SeoAdvance from "../SeoAdvance";
import { InputNumber } from "primereact/inputnumber";

const createValidationSchema = (formData) => {
  const schemaFields = Object.keys(formData).reduce((acc, key) => {
    const field = formData[key];
    let validator = Yup.string();

    if (field.validation) {
      if (field.validation.required) {
        validator = validator.required(
          field.validation.message || "This field is required"
        );
      }
      if (field.validation.minLength) {
        validator = validator.min(
          field.validation.minLength,
          field.validation.message || "Length is too short"
        );
      }
      if (field.type === "number" && field.validation.min) {
        validator = Yup.number()
          .min(
            field.validation.min,
            field.validation.message || "Value is too low"
          )
          .required(field.validation.message || "This field is required");
      }
    }

    acc[key] = validator;
    return acc;
  }, {});

  // Add custom validation for password matching
  if (formData.newPassword && formData.confirmPassword) {
    schemaFields.confirmPassword = schemaFields.confirmPassword.oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match"
    );
  }

  return Yup.object().shape(schemaFields);
};

const ModalForm = ({
  visible,
  onHide,
  onSave,
  formData,
  header = "Dynamic Form",
  isEditMode,
}) => {
  const validationSchema = createValidationSchema(formData);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: Object.keys(formData).reduce((acc, key) => {
      acc[key] = formData[key].value || "";
      return acc;
    }, {}),
  });

  const onSubmit = (values) => {
    onSave(values);
    onHide();
  };

  const renderFormFields = () => {
    return Object.keys(formData).map((key) => {
      const field = formData[key];
      return (
        <div key={key} className="p-field p-grid h3remPhoneInput">
          <label htmlFor={key} className="p-col-12 p-md-4">
            <div className="mb-2 text-lg font-semibold mt-3">{field.label}</div>
          </label>
          <div className="p-col-12 p-md-8">
            <Controller
              name={key}
              control={control}
              render={({ field: controllerField }) => (
                <>
                  {field.type === "text" ||
                  field.type === "number" ||
                  field.type === "date" ||
                  field.type === "password" ||
                  field.type === "email" ? (
                    <InputText
                      id={key}
                      {...controllerField}
                      type={field.type}
                      className={errors[key] ? "p-invalid" : "h-3rem"}
                    />
                  ) : field.type === "calendar" ? (
                    <Calendar
                      id={key}
                      {...controllerField}
                      value={controllerField.value}
                      onChange={(e) => controllerField.onChange(e.value)}
                      showIcon
                      className={errors[key] ? "p-invalid" : "h-3rem"}
                    />
                  ) : field.type === "currency" ? (
                    <InputNumber
                      id={key}
                      value={controllerField.value}
                      locale="id-ID"
                      currency="IDR"
                      onChange={(e) => {
                        controllerField.onChange(e.value);
                        setValue(key, e.value, { shouldValidate: true });
                      }}
                      className={errors[key] ? "p-invalid" : "h-3rem"}
                    />
                  ) : field.type === "dropdown" ? (
                    <Dropdown
                      id={key}
                      {...controllerField}
                      options={field.options}
                      onChange={(e) => controllerField.onChange(e.value)}
                      className={errors[key] ? "p-invalid" : "h-3rem"}
                    />
                  ) : field.type === "phone" ? (
                    <div
                      className={`custom-phone-input ${
                        errors[key] ? "p-invalid" : "h-3rem"
                      }`}
                    >
                      <PhoneInput
                        id={key}
                        {...controllerField}
                        defaultCountry="ID"
                        international
                        countryCallingCodeEditable={false}
                        className="h3remPhone"
                        onChange={(phone) => {
                          controllerField.onChange(phone);
                          setValue(key, phone, { shouldValidate: true });
                        }}
                      />
                    </div>
                  ) : field.type === "textarea" ? (
                    <InputTextarea
                      id={key}
                      {...controllerField}
                      rows={5}
                      cols={30}
                      className={errors[key] ? "p-invalid" : "h-3rem"}
                    />
                  ) : field.type === "seo" ? (
                    <SeoAdvance
                      id={key}
                      onSeoDataChange={(e) => {
                        // const updatedValue = JSON.stringify({
                        //   ...currentValue,
                        //   ...e,
                        // });
                        // controllerField.onChange(updatedValue);
                        // setValue(key, updatedValue, { shouldValidate: true });
                      }}
                    />
                  ) : field.type === "tiptap" ? (
                    <TextEditor
                      id={key}
                      content={controllerField.value}
                      showTemplate={false}
                      className="w-full"
                      onChange={(e) => {
                        controllerField.onChange(e);
                        setValue(key, e, { shouldValidate: true });
                      }}
                    />
                  ) : field.type === "upload_filemanager" ? (
                    <>
                      {/* {controllerField.value} */}
                      <CustomDropzone
                        id={key}
                        initialFileId={controllerField.value}
                        onFileUploaded={(ee) => {
                          controllerField.onChange(ee);
                          setValue(key, ee.id, { shouldValidate: true });
                        }}
                      />
                    </>
                  ) : null}
                  {errors[key] && (
                    <small className="p-error">{errors[key]?.message}</small>
                  )}
                </>
              )}
            />
          </div>
        </div>
      );
    });
  };

  const footer = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={onHide}
        className="p-button-text"
      />
      <Button
        className="bg-core"
        label="Save"
        icon="pi pi-check"
        onClick={handleSubmit(onSubmit)}
        autoFocus
      />
    </div>
  );

  return (
    <Dialog
      header={header}
      visible={visible}
      style={{ width: "600px" }}
      footer={footer}
      onHide={onHide}
      className="p-fluid dialog-costume"
    >
      <style jsx="true">{`
        .custom-phone-input .PhoneInputInput {
          width: 100%;
          border-radius: 4px;
          border: 1px solid var(--surface-border);
          padding: 0.5rem;
          font-size: 1rem;
        }
        .custom-phone-input .PhoneInput {
          width: 100%;
        }
        .custom-phone-input .PhoneInputCountry {
          background-color: var(--surface-a);
          border: 1px solid var(--surface-border);
          border-radius: 4px 0 0 4px;
        }
        .p-invalid .PhoneInputInput {
          border-color: var(--red-500);
        }
      `}</style>
      <form className="pb-4" onSubmit={handleSubmit(onSubmit)}>
        {renderFormFields()}
      </form>
    </Dialog>
  );
};

export default ModalForm;
