import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
import PublicRoute from "./components/common/PublicRoute";
import DashboardPage from "./components/pages/DashboardPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import SettingsPage from "./components/pages/setting/SettingPage";
import EditPassword from "./components/pages/setting/EditPassword";
import EditProfile from "./components/pages/setting/EditProfile";
import MitraPage from "./components/pages/mitra/MitraPage";
import TransaksiPage from "./components/pages/transaksi/TransaksiPage";
import ReportPage from "./components/pages/setting/ReportPage";
import TransaksiDetailPage from "./components/pages/transaksi/TransaksiDetailPage";
import PaymentMetodePage from "./components/pages/transaksi/PaymentMetodePage";
import PaymentMetodeFormPage from "./components/pages/transaksi/PaymentMetodeFormPage";

import JamaahPage from "./components/pages/jamaah/JamaahPage";
import JamaahPageDokument from "./components/pages/jamaah/JamaahPageDokument";

import JadwalPage from "./components/pages/jadwal/JadwalPage";
import JadwalJamaahPage from "./components/pages/jadwal/jamaah/JamaahPage";

import PaketPage from "./components/pages/paket/PaketPage";
import PaketHargaPage from "./components/pages/paket/PaketHargaPage";
import AddPaketPage from "./components/pages/paket/add/AddPaketPage";

import KontenPage from "./components/pages/konten/KontenPage";
import FormKontenPage from "./components/pages/konten/KontenPage/form";

import UserPage from "./components/pages/user/UserPage";
import MasterHotelPage from "./components/pages/master/MasterHotelPage";
import MasterTransportasiPage from "./components/pages/master/MasterTransportasiPage";
import MasterMaskapaiPage from "./components/pages/master/MasterMaskapaiPage";

import MasterPerlengkapanPage from "./components/pages/master/MasterPerlengkapanPage";

import MasterFeaturePage from "./components/pages/master/MasterFeaturePage";
import FormMasterFeaturePage from "./components/pages/master/MasterFeaturePage/form";

import MasterItineraryPage from "./components/pages/master/MasterIteneraryPage";
import FormMasterItineraryPage from "./components/pages/master/MasterIteneraryPage/form";

import MasterConditionPage from "./components/pages/master/MasterCoditionPage";
import FormMasterConditionPage from "./components/pages/master/MasterCoditionPage/form";

import ArtikelPage from "./components/pages/website/ArtikelPage";
import ArtikelCategoryPage from "./components/pages/website/ArtikelCategoryPage";
import GalleryPage from "./components/pages/website/GalleryPage";
import GalleryDetailPage from "./components/pages/website/GalleryDetailPage";
import SliderPage from "./components/pages/website/SliderPage";
import TestimoniPage from "./components/pages/website/TestimoniPage";

//auth page
import LoginPage from "./components/pages/auth/LoginPage";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <Router>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/" element={<LoginPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<DashboardPage />} />

            <Route path="/jamaah" element={<JamaahPage />} />
            <Route
              path="/jamaah/dokument/:idpilgrim"
              element={<JamaahPageDokument />}
            />

            <Route path="/user" element={<UserPage />} />

            <Route path="/paket/harga" element={<PaketHargaPage />} />
            <Route path="/paket" element={<PaketPage />} />
            <Route path="/paket/add" element={<AddPaketPage />} />
            <Route path="/paket/edit/:id" element={<AddPaketPage />} />

            <Route path="/paket/form" element={<JamaahPage />} />
            <Route path="/paket/form/:id" element={<JamaahPage />} />

            <Route path="/jadwal" element={<JadwalPage />} />
            <Route path="/jadwal/jamaah/:id" element={<JadwalJamaahPage />} />
            <Route path="/jadwal/form" element={<JamaahPage />} />
            <Route path="/jadwal/form/:id" element={<JamaahPage />} />

            <Route path="/konten" element={<KontenPage />} />
            <Route path="/konten/form" element={<FormKontenPage />} />
            <Route path="/konten/form/:id" element={<FormKontenPage />} />

            <Route path="/master" element={<JamaahPage />} />
            <Route path="/master/hotel" element={<MasterHotelPage />} />
            <Route path="/master/maskapai" element={<MasterMaskapaiPage />} />
            <Route
              path="/master/transport"
              element={<MasterTransportasiPage />}
            />
            <Route
              path="/master/perlengkapan"
              element={<MasterPerlengkapanPage />}
            />

            <Route path="/master/feature" element={<MasterFeaturePage />} />
            <Route
              path="/master/feature/form"
              element={<FormMasterFeaturePage />}
            />
            <Route
              path="/master/feature/form/:id"
              element={<FormMasterFeaturePage />}
            />

            <Route path="/master/itinerary" element={<MasterItineraryPage />} />
            <Route
              path="/master/itinerary/form"
              element={<FormMasterItineraryPage />}
            />
            <Route
              path="/master/itinerary/form/:id"
              element={<FormMasterItineraryPage />}
            />

            <Route path="/master/condition" element={<MasterConditionPage />} />
            <Route
              path="/master/condition/form"
              element={<FormMasterConditionPage />}
            />
            <Route
              path="/master/condition/form/:id"
              element={<FormMasterConditionPage />}
            />

            <Route path="/website" element={<JamaahPage />} />
            <Route path="/website/mitra" element={<MitraPage />} />
            <Route path="/website/artikel" element={<ArtikelPage />} />
            <Route
              path="/website/artikel/category"
              element={<ArtikelCategoryPage />}
            />
            <Route path="/website/galeri" element={<GalleryPage />} />
            <Route
              path="/website/galeri/:idgaleri"
              element={<GalleryDetailPage />}
            />
            <Route path="/website/slider" element={<SliderPage />} />
            <Route path="/website/testimoni" element={<TestimoniPage />} />

            <Route path="/transaksi" element={<TransaksiPage />} />
            <Route
              path="/transaksi/payment/method"
              element={<PaymentMetodePage />}
            />
            <Route
              path="/transaksi/payment/method/form/:id"
              element={<PaymentMetodeFormPage />}
            />
            <Route path="/transaksi/:id" element={<TransaksiDetailPage />} />

            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/settings/edit-password" element={<EditPassword />} />
            <Route path="/settings/edit-profile" element={<EditProfile />} />
            <Route path="/settings/report" element={<ReportPage />} />
          </Route>
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
