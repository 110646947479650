import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import api from "../../util/axiosConfig";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  email: yup.string().email("Email tidak valid").required("Email diperlukan"),
  password: yup
    .string()
    .required("Password diperlukan")
    .min(6, "Password harus minimal 6 karakter"),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await api.post("/auth/login", data);
      const { token } = response.data.data;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("userID", response.data.data.user.id);
      localStorage.setItem(
        "packageStatus",
        JSON.stringify(response.data.data.package)
      );
      navigate("/dashboard"); // Redirect ke dashboard setelah login
    } catch (error) {
      toast.error(
        "Login gagal: " + (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="register-container login-container">
      <div className="block md:flex card-register-new card-login-new costume-form p-4 md:p-6">
        <div className="w-full">
          <img src={`/logo.png`} alt="Logo" className="login-logo" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-field">
              <label htmlFor="email" className="text-md pb-2 font-semibold">
                Email
              </label>
              <InputText
                id="email"
                {...register("email")}
                className={errors.email ? "p-invalid" : ""}
              />
              {errors.email && (
                <small className="p-error">{errors.email.message}</small>
              )}
            </div>
            <div className="p-field">
              <label htmlFor="password" className="text-md pb-2 font-semibold">
                Kata Sandi
              </label>
              <Password
                id="password"
                toggleMask
                className={errors.password ? "p-invalid" : ""}
                onChange={(e) =>
                  setValue("password", e.target.value, { shouldValidate: true })
                }
                feedback={false}
              />
              <div className="flex items-center  w-full justify-content-between">
                {errors.password ? (
                  <small className="p-error pt-2">
                    {errors.password.message}
                  </small>
                ) : (
                  <div> </div>
                )}
              </div>
            </div>
            <div>
              <Button
                type="submit"
                label="Login"
                className="w-full font-bold text-lg py-3 mt-3"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
