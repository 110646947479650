import React, { useState } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalForm from "../../util/dynamic/formDinamis";
import TableDinamis from "../../util/dynamic/tableDinamis";
import api from "../../util/axiosConfig";
import { Link, useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";

const Page = () => {
  const navigate = useNavigate();

  const [refresh, setRefresh] = useState(0);
  const handleDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to delete this item?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await api.delete(`/paket/${id}`);
          toast.success("Data deleted successfully!");
          setRefresh(refresh + 1);
        } catch (error) {
          toast.error("An error occurred while deleting data.");
        }
      },
      reject: () => {
        toast.info("Delete action was canceled.");
      },
    });
  };
  return (
    <div className="w-full">
      <TableDinamis
        enableCaching={false}
        title="Paket"
        headerComponents={[
          () => (
            <Button
              label="Add Paket"
              icon="pi pi-plus"
              className="btn-core mr-2"
              onClick={() => navigate("/paket/add")}
            />
          ),
          () => (
            <Button
              label="Setup Harga"
              icon="pi pi-money-bill"
              className="btn-core mr-2"
              onClick={() => navigate("/paket/harga")}
            />
          ),
        ]}
        id="paket"
        path="/paket"
        onRefresh={refresh}
        kolom={[
          {
            field: "title",
            label: "Nama Paket",
            sort: true,
          },
          {
            field: "spec.title",
            label: "Spec",
            sort: true,
          },
          {
            field: "harga.title",
            label: "Price",
            sort: true,
          },
          {
            field: "seo.title",
            label: "SEO",
            sort: true,
          },
          {
            field: "status",
            label: "Status",
            sort: true,
          },
        ]}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Edit"
              className="mr-2 font-bold"
              severity="info"
              outlined
              onClick={() => navigate(`/paket/edit/${rowData.id}`)}
            />
            <Button
              label="Delete"
              className="mr-2 font-bold btn-danger-outlined"
              severity="danger"
              outlined
              onClick={() => handleDelete(rowData.id)}
            />
          </div>
        )}
      />

      <ConfirmDialog />
    </div>
  );
};

export default Page;
