// src/components/common/PrivateRoute.js
import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { jwtDecode } from "jwt-decode"; // Ubah impor ini

const AuthCheck = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000; // Waktu saat ini dalam detik
          if (decodedToken.exp > currentTime) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error("Failed to decode token:", error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }

      setAuthChecked(true);
    };

    checkAuth();
  }, []);

  if (!authChecked) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    localStorage.clear();
    sessionStorage.clear();
    return <Navigate to="/" />;
  }

  return children;
};

const PrivateRoute = () => {
  return (
    <AuthCheck>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </AuthCheck>
  );
};

export default PrivateRoute;
