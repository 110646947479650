import React, { useState, useEffect } from "react";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";

const SeoAdvance = ({ onSeoDataChange }) => {
  const [checked, setChecked] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    onSeoDataChange({
      advance_seo: checked,
      seo_title: title,
      seo_description: description,
    });
  }, [title, description, onSeoDataChange]);

  return (
    <div>
      <div className="p-field-checkbox">
        <Checkbox
          inputId="seo"
          checked={checked}
          onChange={(e) => setChecked(e.checked)}
        />
        <label htmlFor="seo"> SEO Advance</label>
      </div>

      {checked && (
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="title">Title</label>
            <InputText
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="p-field">
            <label htmlFor="description">Description</label>
            <InputText
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SeoAdvance;
