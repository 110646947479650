import React, { useState } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalForm from "../../util/dynamic/formDinamis";
import TableDinamis from "../../util/dynamic/tableDinamis";
import api from "../../util/axiosConfig";
import { Image } from "primereact/image";

const Page = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectID, setSelectID] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [refresh, setRefresh] = useState(0);

  // Fungsi untuk menangani penambahan item baru
  const handleAdd = () => {
    setIsEditMode(false);
    setFormData({
      image_id: {
        label: "Avatar",
        value: "",
        type: "upload_filemanager",
        // validation: {
        //   required: true,
        // },
      },
      title: {
        label: "Title",
        value: "",
        type: "text",
        validation: {
          required: true,
        },
      },
      description: {
        label: "Description",
        value: "",
        type: "textarea",
      },
      feature_type: {
        label: "Type",
        value: "",
        type: "dropdown",
        options: [
          { label: "Ekonomi", value: "ekonomi" },
          { label: "Bisnis", value: "bisnis" },
          { label: "Platinum", value: "platinum" },
        ],
      },
      rating: {
        label: "Rating",
        value: null,
        type: "number",
      },
      distance: {
        label: "Jarak",
        value: "",
        type: "text",
      },
      fnb: {
        label: "Makanan & Minuman",
        value: "",
        type: "text",
      },
      economy_baggage: {
        label: "Bagasi Ekonomi",
        value: "",
        type: "text",
      },
      executive_baggage: {
        label: "Bagasi Eksekutif",
        value: "",
        type: "text",
      },
      cabin: {
        label: "Kabin",
        value: "",
        type: "text",
      },
    });
    setShowModal(true);
  };

  // Fungsi untuk menangani pengeditan item yang ada
  const handleEdit = async (id) => {
    setSelectID(id);
    const result = await api.get(`/master/maskapai/${id}`);
    setIsEditMode(true);
    setFormData({
      image_id: {
        label: "Avatar",
        value: result.data.data.image_id,
        type: "upload_filemanager",
        // validation: {
        //   required: true,
        // },
      },
      title: {
        label: "Title",
        value: result.data.data.title,
        type: "text",
        validation: {
          required: true,
        },
      },
      description: {
        label: "Description",
        value: result.data.data.description,
        type: "textarea",
      },
      feature_type: {
        label: "Type",
        value: result.data.data.feature_type,
        type: "dropdown",
        options: [
          { label: "Ekonomi", value: "ekonomi" },
          { label: "Bisnis", value: "bisnis" },
          { label: "Platinum", value: "platinum" },
        ],
      },
      rating: {
        label: "Rating",
        value: result.data.data.rating,
        type: "number",
      },
      distance: {
        label: "Jarak",
        value: result.data.data.distance,
        type: "text",
      },
      fnb: {
        label: "Makanan & Minuman",
        value: result.data.data.fnb,
        type: "text",
      },
      economy_baggage: {
        label: "Bagasi Ekonomi",
        value: result.data.data.economy_baggage,
        type: "text",
      },
      executive_baggage: {
        label: "Bagasi Eksekutif",
        value: result.data.data.executive_baggage,
        type: "text",
      },
      cabin: {
        label: "Kabin",
        value: result.data.data.cabin,
        type: "text",
      },
    });
    setShowModal(true);
  };

  // Fungsi untuk menangani penyimpanan data
  const handleSave = async (data) => {
    try {
      if (!isEditMode) {
        await api.post(`/master/maskapai`, data);
      } else {
        await api.put(`/master/maskapai/${selectID}`, data);
      }
      toast.success("Data saved successfully!");
      setRefresh(refresh + 1);
      setShowModal(false);
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while saving data.");
      }
    }
  };

  // Fungsi untuk menangani penghapusan item
  const handleDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to delete this item?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await api.delete(`/master/maskapai/${id}`);
          toast.success("Data deleted successfully!");
          setRefresh(refresh + 1);
        } catch (error) {
          toast.error("An error occurred while deleting data.");
        }
      },
      reject: () => {
        toast.info("Delete action was canceled.");
      },
    });
  };

  return (
    <div className="w-full">
      {showModal && (
        <ModalForm
          visible={showModal}
          onHide={() => setShowModal(false)}
          onSave={handleSave}
          formData={formData}
          isEditMode={isEditMode}
        />
      )}

      <TableDinamis
        title="Master Maskapai"
        headerComponents={[
          () => (
            <Button
              label="Add Maskapai"
              icon="pi pi-plus"
              onClick={handleAdd}
              className="btn-core mr-2"
            />
          ),
        ]}
        id="maskapai"
        path="/master/maskapai"
        onRefresh={refresh}
        kolom={[
          {
            field: "file.url_file",
            label: "Image",
            type: "custom",
            component: (rowData) => (
              <Image
                src={rowData.file.url_file}
                zoomSrc={rowData.file.url_file}
                alt="Image"
                width="80"
                height="60"
                preview
              />
            ),
            sort: true,
          },
          {
            field: "title",
            label: "Title",
            sort: true,
          },
          {
            field: "feature_type",
            label: "Type",
            sort: true,
          },
          {
            field: "status",
            label: "Status",
            sort: true,
          },
          //   {
          //     field: "rating",
          //     label: "Rating",
          //     sort: true,
          //   },
        ]}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Edit"
              className="mr-2 font-bold btn-succes-outlined"
              severity="success"
              outlined
              onClick={() => handleEdit(rowData.id)}
            />
            <Button
              label="Delete"
              className="mr-2 font-bold btn-danger-outlined"
              severity="danger"
              outlined
              onClick={() => handleDelete(rowData.id)}
            />
          </div>
        )}
      />

      <ConfirmDialog />
    </div>
  );
};

export default Page;
