import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Message } from "primereact/message";
import { Checkbox } from "primereact/checkbox";
import "react-toastify/dist/ReactToastify.css";
import ModalForm from "../../util/dynamic/formDinamis";
import TableDinamis from "../../util/dynamic/tableDinamis";
import api from "../../util/axiosConfig";
import { Link, useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";

const JamaahDetailPage = () => {
  const { idpilgrim } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [detailData, setDetailData] = useState({});
  const [selectID, setSelectID] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [file, setFile] = useState(null);
  const fileUploadRef = useRef(null);
  const [checked, setChecked] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);

  const handleAdd = () => {
    setIsEditMode(false);
    setFormData({
      name: {
        label: "Name",
        value: "",
        type: "text",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      document_id: {
        label: "File",
        value: "",
        type: "upload_filemanager",
        validation: {
          required: true,
        },
      },
    });
    setShowModal(true);
  };

  const handleEdit = async (id) => {
    setSelectID(id);
    const result = await api.get(`/jamaah/dokumen/${idpilgrim}/detail/${id}`);
    setIsEditMode(true);
    setFormData({
      name: {
        label: "Name",
        value: result.data.data.name,
        type: "text",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      document_id: {
        label: "File",
        value: result.data.data.document_id,
        type: "upload_filemanager",
        validation: {
          required: true,
        },
      },
    });
    setShowModal(true);
  };

  const handleSave = async (data) => {
    try {
      if (!isEditMode) {
        await api.post(`/jamaah/dokumen/${idpilgrim}`, data);
      } else {
        await api.put(`/jamaah/dokumen/${idpilgrim}/detail/${selectID}`, data);
      }
      toast.success("Data saved successfully!");
      setRefresh(refresh + 1);
      setShowModal(false);
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while saving data.");
      }
    }
  };

  const handleDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to delete this dokumen jamaah?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await api.delete(`/jamaah/dokumen/${idpilgrim}/detail/${id}`);
          toast.success("Jamaah deleted successfully!");
          setRefresh(refresh + 1);
        } catch (error) {
          toast.error("An error occurred while deleting Jamaah.");
        }
      },
      reject: () => {
        toast.info("Delete action was canceled.");
      },
    });
  };

  const handleDetail = async (id) => {
    const result = await api.get(`/jamaah/dokumen/${idpilgrim}/detail/${id}`);
    setDetailData({
      ...result.data.data.file,
    });
    setShowDetailModal(true);
  };

  const columns = [
    { field: "name", label: "Name", sort: true },
    { field: "file.url_file", label: "File", sort: true },
  ];

  return (
    <div className="page-container">
      {showModal && (
        <ModalForm
          visible={showModal}
          onHide={() => setShowModal(false)}
          onSave={handleSave}
          formData={formData}
          isEditMode={isEditMode}
        />
      )}

      {showDetailModal && (
        <Dialog
          visible={showDetailModal}
          onHide={() => setShowDetailModal(false)}
          header="Dokumen Jamaah Details"
          style={{ width: "50vw" }}>
          <div>
            <p>
              <strong>Name:</strong> {detailData.nama_file}
            </p>
            <p>
              <strong>File:</strong> {detailData.url_file}
            </p>
          </div>
        </Dialog>
      )}

      <TableDinamis
        title="Jamaah Dokumen"
        headerComponents={[
          () => (
            <Button
              label="Add Dokumen Jamaah"
              onClick={handleAdd}
              icon="pi pi-file-plus"
              className="btn-core mr-2"
            />
          ),
        ]}
        id="jamaahDokumen"
        path={`/jamaah/dokumen/${idpilgrim}`}
        onRefresh={refresh}
        kolom={columns}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Detail"
              className="mr-2 font-bold"
              severity="info"
              outlined
              onClick={() => handleDetail(rowData.id)}
            />
            <Button
              label="Edit"
              className="mr-2 font-bold btn-succes-outlined"
              severity="success"
              outlined
              onClick={() => handleEdit(rowData.id)}
            />
            <Button
              label="Delete"
              className="mr-2 font-bold btn-danger-outlined"
              severity="danger"
              outlined
              onClick={() => handleDelete(rowData.id)}
            />
          </div>
        )}
      />

      <ConfirmDialog />
    </div>
  );
};

export default JamaahDetailPage;
