import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalForm from "../../../util/dynamic/formDinamis";
import TableDinamis from "../../../util/dynamic/tableDinamis";
import api from "../../../util/axiosConfig";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import moment from "moment";

const Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [paket, setPaket] = useState([]);
  const [selectID, setSelectID] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchPaketData();
  }, []);

  const fetchPaketData = async () => {
    try {
      const response = await api.get("/paket");
      console.log(response.data.data);
      setPaket(response.data.data);
    } catch (error) {
      console.error("Error fetching spec data:", error);
    }
  };

  const paketOptions = paket.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const handleAdd = () => {
    setIsEditMode(false);
    setFormData({
      title: {
        label: "Title",
        value: "",
        type: "text",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      description: {
        label: "Description",
        value: "",
        type: "tiptap",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },

      departure_date: {
        label: "Departure",
        value: "",
        type: "date",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      arrival_date: {
        label: "Arrival",
        value: "",
        type: "date",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      quota: {
        label: "Quota",
        value: "",
        type: "number",
        validation: {
          required: true,
          minLength: 1,
          message: "Please enter at least 3 characters.",
        },
      },
      package_id: {
        label: "Paket",
        value: "",
        type: "dropdown",
        options: [...paketOptions],
        validation: { required: true, message: "Please select a gender." },
      },
      status: {
        label: "Status",
        value: "",
        type: "dropdown",
        options: [
          { label: "Aktif", value: true },
          { label: "False", value: false },
        ],
        validation: { required: true, message: "Please select a status." },
      },
    });
    setShowModal(true);
  };

  const handleSave = async (data) => {
    try {
      console.log(data);
      if (!isEditMode) {
        await api.post(`/jadwal`, data);
      } else {
        await api.put(`/jadwal/${selectID}`, data);
      }
      toast.success("Data saved successfully!");
      setRefresh(refresh + 1);
      setShowModal(false);
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while saving data.");
      }
    }
  };

  return (
    <div className="w-full">
      {showModal && (
        <ModalForm
          visible={showModal}
          onHide={() => setShowModal(false)}
          onSave={handleSave}
          formData={formData}
          isEditMode={isEditMode}
        />
      )}

      <TableDinamis
        title="Umrah Jamaah"
        id="umrahjamaah"
        path={`/jadwal/${id}/jamaah`}
        onRefresh={refresh}
        kolom={[
          {
            field: "jamaah.fullname",
            label: "Nama",
            sort: true,
          },
          {
            field: "jamaah.phone",
            label: "No HP",
            sort: true,
          },
          {
            field: "jamaah.email",
            label: "Email",
            sort: true,
          },
        ]}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Lihat Transaksi"
              className="mr-2 font-bold"
              severity="info"
              outlined
              onClick={() =>
                navigate(`/transaksi/${rowData.transaction_group_id}`)
              }
            />
          </div>
        )}
      />

      <ConfirmDialog />
    </div>
  );
};

export default Page;
