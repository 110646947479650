import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalForm from "../../util/dynamic/formDinamis";
import TableDinamis from "../../util/dynamic/tableDinamis";
import api from "../../util/axiosConfig";
import { Link, useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";
import moment from "moment";

const Page = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [paket, setPaket] = useState([]);
  const [selectID, setSelectID] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchPaketData();
  }, []);

  const fetchPaketData = async () => {
    try {
      const response = await api.get("/paket");
      console.log(response.data.data);
      setPaket(response.data.data);
    } catch (error) {
      console.error("Error fetching spec data:", error);
    }
  };

  //   console.log(paket);

  const paketOptions = paket.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  // Fungsi untuk menangani penambahan item baru
  const handleAdd = () => {
    setIsEditMode(false);
    setFormData({
      cover_id: {
        label: "Avatar",
        value: "",
        type: "upload_filemanager",
      },
      title: {
        label: "Title",
        value: "",
        type: "text",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      description: {
        label: "Description",
        value: "",
        type: "tiptap",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      departure_date: {
        label: "Departure",
        value: "",
        type: "date",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      arrival_date: {
        label: "Arrival",
        value: "",
        type: "date",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      quota: {
        label: "Quota",
        value: "",
        type: "number",
        validation: {
          required: true,
          minLength: 1,
          message: "Please enter at least 3 characters.",
        },
      },
      package_id: {
        label: "Paket",
        value: "",
        type: "dropdown",
        options: [...paketOptions],
        validation: { required: true, message: "Please select a gender." },
      },
      status: {
        label: "Status",
        value: "",
        type: "dropdown",
        options: [
          { label: "Aktif", value: true },
          { label: "False", value: false },
        ],
        validation: { required: true, message: "Please select a status." },
      },
    });
    setShowModal(true);
  };

  // Fungsi untuk menangani pengeditan item yang ada
  const handleEdit = async (id) => {
    setSelectID(id);
    const result = await api.get(`/jadwal/${id}`);
    console.log(result.data.data.departure_date);
    const formattedDate = moment(result.data.data.departure_date).format(
      "DD/MM/YYYY"
    );
    console.log(formattedDate);
    console.log("Dwadwad");

    setIsEditMode(true);
    setFormData({
      cover_id: {
        label: "Avatar",
        value: result.data.data.cover_id,
        type: "upload_filemanager",
      },
      title: {
        label: "Title",
        value: result.data.data.title,
        type: "text",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      description: {
        label: "Description",
        value: result.data.data.description,
        type: "tiptap",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      departure_date: {
        label: "Departure",
        value: result.data.data.departure_date,
        type: "date",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      arrival_date: {
        label: "Arrival",
        value: result.data.data.arrival_date,
        type: "date",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      quota: {
        label: "Quota",
        value: result.data.data.quota,
        type: "number",
        validation: {
          required: true,
          minLength: 1,
          message: "Please enter at least 3 characters.",
        },
      },
      package_id: {
        label: "Paket",
        value: result.data.data.package_id,
        type: "dropdown",
        options: [...paketOptions],
        validation: { required: true, message: "Please select a gender." },
      },
      status: {
        label: "Status",
        value: result.data.data.status,
        type: "dropdown",
        options: [
          { label: "Aktif", value: true },
          { label: "False", value: false },
        ],
        validation: { required: true, message: "Please select a status." },
      },
    });
    setShowModal(true);
  };

  const handleSave = async (data) => {
    try {
      console.log(data);
      if (!isEditMode) {
        await api.post(`/jadwal`, data);
      } else {
        await api.put(`/jadwal/${selectID}`, data);
      }
      toast.success("Data saved successfully!");
      setRefresh(refresh + 1);
      setShowModal(false);
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while saving data.");
      }
    }
  };

  const handleDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to delete this item?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await api.delete(`/jadwal/${id}`);
          toast.success("Data deleted successfully!");
          setRefresh(refresh + 1);
        } catch (error) {
          toast.error("An error occurred while deleting data.");
        }
      },
      reject: () => {
        toast.info("Delete action was canceled.");
      },
    });
  };

  return (
    <div className="w-full">
      {showModal && (
        <ModalForm
          visible={showModal}
          onHide={() => setShowModal(false)}
          onSave={handleSave}
          formData={formData}
          isEditMode={isEditMode}
        />
      )}

      <TableDinamis
        title="Jadwal"
        headerComponents={[
          () => (
            <Button
              label="Add Jadwal"
              icon="pi pi-plus"
              onClick={handleAdd}
              className="btn-core mr-2"
            />
          ),
        ]}
        id="jadwal"
        path="/jadwal"
        onRefresh={refresh}
        kolom={[
          {
            field: "paket.title",
            label: "Umrah Package",
            sort: true,
          },
          {
            field: "title",
            label: "Name",
            sort: true,
          },
          {
            field: "departure_date",
            label: "Departure",
            sort: true,
          },
          {
            field: "arrival_date",
            label: "Arrival",
            sort: true,
          },
          {
            field: "quota",
            label: "Quota",
            sort: true,
          },
          {
            field: "status",
            label: "Status",
            sort: true,
          },
        ]}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Edit"
              className="mr-2 font-bold btn-succes-outlined"
              severity="success"
              outlined
              onClick={() => handleEdit(rowData.id)}
            />
            <Button
              label="Jamaah"
              className="mr-2 font-bold"
              severity="info"
              outlined
              onClick={() => navigate(`/jadwal/jamaah/${rowData.id}`)}
            />
            <Button
              label="Delete"
              className="mr-2 font-bold btn-danger-outlined"
              severity="danger"
              outlined
              onClick={() => handleDelete(rowData.id)}
            />
          </div>
        )}
      />

      <ConfirmDialog />
    </div>
  );
};

export default Page;
