import React, { Fragment, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { ButtonGroup } from "primereact/buttongroup";
import { InputText } from "primereact/inputtext";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../util/axiosConfig";
import "react-toastify/dist/ReactToastify.css";

const Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form, setForm] = useState({
    title: "",
    termasuk: [""],
    tidaktermasuk: [""],
  });

  useEffect(() => {
    if (id) {
      api.get(`/master/fitur/${id}`).then((response) => {
        console.log(response.data);
        const data = response.data.data;
        setForm({
          title: data.title || "",
          termasuk: data.data.termasuk || [""],
          tidaktermasuk: data.data.tidaktermasuk || [""],
        });
      });
    }
  }, [id]);

  const handleAdd = (type) => {
    setForm((prevState) => ({
      ...prevState,
      [type]: [...prevState[type], ""],
    }));
  };

  const handleDelete = (type, index) => {
    if (form[type].length > 1) {
      setForm((prevState) => ({
        ...prevState,
        [type]: prevState[type].filter((_, i) => i !== index),
      }));
    }
  };

  const handleChange = (type, index, value) => {
    const newArray = [...form[type]];
    newArray[index] = value;
    setForm((prevState) => ({
      ...prevState,
      [type]: newArray,
    }));
  };

  const handleTitleChange = (e) => {
    setForm((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const dataToSend = {
      title: form.title,
      data: {
        termasuk: form.termasuk,
        tidaktermasuk: form.tidaktermasuk,
      },
    };

    if (id) {
      api
        .put(`/master/fitur/${id}`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          navigate("/master/feature"); // Navigate to success page after edit
        });
    } else {
      api
        .post("/master/fitur", dataToSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          navigate("/master/feature"); // Navigate to success page after submission
        });
    }
  };

  return (
    <div className="w-full">
      <form onSubmit={onSubmit}>
        <div className="flex flex-column gap-2">
          <label htmlFor="title">Title</label>
          <InputText
            value={form.title}
            onChange={handleTitleChange}
            aria-describedby="title-help"
          />
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="termasuk">Fitur Termasuk</label>
          {form.termasuk.map((item, index) => (
            <Fragment key={`termasuk-${index}`}>
              <InputText
                value={item}
                onChange={(e) =>
                  handleChange("termasuk", index, e.target.value)
                }
                aria-describedby="termasuk-help"
              />
              <ButtonGroup>
                {index === form.termasuk.length - 1 && (
                  <Button
                    label="Add"
                    icon="pi pi-plus"
                    onClick={() => handleAdd("termasuk")}
                  />
                )}
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  onClick={() => handleDelete("termasuk", index)}
                  disabled={form.termasuk.length === 1}
                />
              </ButtonGroup>
            </Fragment>
          ))}
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="tidaktermasuk">Fitur Tidak Termasuk</label>
          {form.tidaktermasuk.map((item, index) => (
            <Fragment key={`tidaktermasuk-${index}`}>
              <InputText
                value={item}
                onChange={(e) =>
                  handleChange("tidaktermasuk", index, e.target.value)
                }
                aria-describedby="tidaktermasuk-help"
              />
              <ButtonGroup>
                {index === form.tidaktermasuk.length - 1 && (
                  <Button
                    label="Add"
                    icon="pi pi-plus"
                    onClick={() => handleAdd("tidaktermasuk")}
                  />
                )}
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  onClick={() => handleDelete("tidaktermasuk", index)}
                  disabled={form.tidaktermasuk.length === 1}
                />
              </ButtonGroup>
            </Fragment>
          ))}
        </div>
        <Button type="submit" label="Submit" />
      </form>
    </div>
  );
};

export default Page;
