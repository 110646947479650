import React from "react";
import { useNavigate } from "react-router-dom";
import { PanelMenu } from "primereact/panelmenu";
import { Card } from "primereact/card";

const SettingsPage = () => {
  const navigate = useNavigate();

  const items = [
    {
      label: "Edit Password",
      icon: "pi pi-key",
      command: () => {
        navigate("/settings/edit-password");
      },
    },
    {
      label: "Edit Profile",
      icon: "pi pi-user-edit",
      command: () => {
        navigate("/settings/edit-profile");
      },
    },
    {
      label: "Transaksi",
      icon: "pi pi-money-bill",
      command: () => {
        navigate("/settings/transaksi");
      },
    },
    {
      label: "Report",
      icon: "pi pi-chart-bar",
      command: () => {
        navigate("/settings/report");
      },
    },
  ];

  return (
    <div className="p-d-flex p-jc-center p-ai-start p-mt-4">
      <Card className="p-shadow-3" style={{ width: "300px" }}>
        <h2>Settings</h2>
        <PanelMenu model={items} className="p-mt-4" />
      </Card>
    </div>
  );
};

export default SettingsPage;
