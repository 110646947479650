import React, { useState } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalForm from "../../util/dynamic/formDinamis";
import TableDinamis from "../../util/dynamic/tableDinamis";
import api from "../../util/axiosConfig";

const Page = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalPw, setShowModalPw] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectID, setSelectID] = useState(null);
  const [selectIDPw, setSelectIDPw] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [passwordForm, setPasswordForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // Fungsi untuk menangani penambahan item baru
  const handleAdd = () => {
    setIsEditMode(false);
    setFormData({
      name: {
        label: "Name",
        value: "",
        type: "text",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      email: {
        label: "Email",
        value: "",
        type: "email",
        validation: {
          required: true,
          message: "Please enter a valid email address.",
        },
      },
      phone: {
        label: "Phone",
        value: "",
        type: "phone",
        validation: {
          required: true,
          minLength: 10,
          message: "Please enter a valid phone number.",
        },
      },
      password: {
        label: "Password",
        value: "",
        type: "password",
        validation: {
          required: true,
        },
      },
      //   role: {
      //     label: "Role",
      //     value: "",
      //     type: "dropdown",
      //     options: [
      //       { label: "Lead", value: "lead" },
      //       { label: "Jamaah", value: "pilgrim" },
      //       { label: "Alumni", value: "alumni" },
      //     ],
      //     validation: { required: true, message: "Please select a status." },
      //   },
    });
    setShowModal(true);
  };

  const handleEditPw = async (id) => {
    setSelectIDPw(id);
    setPasswordForm({
      oldPassword: {
        label: "Password Lama",
        value: "",
        type: "password",
        validation: {
          required: true,
        },
      },
      newPassword: {
        label: "Password Baru",
        value: "",
        type: "password",
        validation: {
          required: true,
        },
      },
      confirmPassword: {
        label: "Konfirmasi Password",
        value: "",
        type: "password",
        validation: {
          required: true,
        },
      },
    });
    setShowModalPw(true);
  };

  // Fungsi untuk menangani pengeditan item yang ada
  const handleEdit = async (id) => {
    setSelectID(id);
    const result = await api.get(`/user/${id}`);
    setIsEditMode(true);
    setFormData({
      name: {
        label: "Name",
        value: result.data.data.name,
        type: "text",
        validation: {
          required: true,
          minLength: 3,
          message: "Please enter at least 3 characters.",
        },
      },
      email: {
        label: "Email",
        value: result.data.data.email,
        type: "email",
        validation: {
          required: true,
          message: "Please enter a valid email address.",
        },
      },
      phone: {
        label: "Phone",
        value: result.data.data.phone,
        type: "phone",
        validation: {
          required: true,
          minLength: 10,
          message: "Please enter a valid phone number.",
        },
      },
      //   role: {
      //     label: "Role",
      //     value: result.data.data.role,
      //     type: "dropdown",
      //     options: [
      //       { label: "Lead", value: "lead" },
      //       { label: "Jamaah", value: "pilgrim" },
      //       { label: "Alumni", value: "alumni" },
      //     ],
      //     validation: { required: true, message: "Please select a status." },
      //   },
    });
    setShowModal(true);
  };

  // Fungsi untuk menangani penyimpanan data
  const handleSave = async (data) => {
    try {
      if (!isEditMode) {
        await api.post(`/user`, data);
      } else {
        await api.put(`/user/${selectID}`, data);
      }
      toast.success("Data saved successfully!");
      setRefresh(refresh + 1);
      setShowModal(false);
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while saving data.");
      }
    }
  };

  const handleSavePassword = async (data) => {
    try {
      await api.put(`/user/edit-password/${selectIDPw}`, data);
      toast.success("Password updated successfully!");
      setRefresh(refresh + 1);
      setShowModalPw(false);
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while updating the password.");
      }
    }
  };

  // Fungsi untuk menangani penghapusan item
  const handleDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to delete this item?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await api.delete(`/user/${id}`);
          toast.success("Data deleted successfully!");
          setRefresh(refresh + 1);
        } catch (error) {
          toast.error("An error occurred while deleting data.");
        }
      },
      reject: () => {
        toast.info("Delete action was canceled.");
      },
    });
  };

  return (
    <div className="w-full">
      {showModal && (
        <ModalForm
          visible={showModal}
          onHide={() => setShowModal(false)}
          onSave={handleSave}
          formData={formData}
          isEditMode={isEditMode}
        />
      )}

      {showModalPw && (
        <ModalForm
          visible={showModalPw}
          onHide={() => setShowModalPw(false)}
          onSave={handleSavePassword}
          formData={passwordForm}
          selectIDPw={selectIDPw}
        />
      )}

      <TableDinamis
        title="User"
        headerComponents={[
          () => (
            <Button
              label="Add User"
              icon="pi pi-plus"
              onClick={handleAdd}
              className="btn-core mr-2"
            />
          ),
        ]}
        id="user"
        path="/user"
        onRefresh={refresh}
        kolom={[
          {
            field: "name",
            label: "Name",
            sort: true,
          },
          {
            field: "email",
            label: "Email",
            sort: true,
          },
          {
            field: "phone",
            label: "Phone",
            sort: true,
          },
          {
            field: "role",
            label: "Type",
            sort: true,
          },
        ]}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Edit"
              className="mr-2 font-bold btn-succes-outlined"
              severity="success"
              outlined
              onClick={() => handleEdit(rowData.id)}
            />
            <Button
              label="Ubah Password"
              className="mr-2 font-bold btn-warning-outlined"
              severity="warning"
              outlined
              onClick={() => handleEditPw(rowData.id)}
            />
            <Button
              label="Delete"
              className="mr-2 font-bold btn-danger-outlined"
              severity="danger"
              outlined
              onClick={() => handleDelete(rowData.id)}
            />
          </div>
        )}
      />

      <ConfirmDialog />
    </div>
  );
};

export default Page;
