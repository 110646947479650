import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import api from "../axiosConfig";

const FromSPec = ({ details, setDetails, titleSpec, setTitleSpec }) => {
  const [formOptions, setFormOptions] = useState({
    hotel: [],
    flight: [],
    transport: [],
    feature: [],
    intenerary: [],
    condition: [],
    provision: [],
  });

  const [formValues, setFormValues] = useState({
    hotelId: null,
    flightId: null,
    transportId: null,
    featureId: null,
    inteneraryId: null,
    conditionId: null,
    provisionId: null,
  });

  useEffect(() => {
    fetchData("hotel", "/master/hotel");
    fetchData("flight", "/master/maskapai");
    fetchData("transport", "/master/transportasi");
    fetchData("feature", "/master/fitur");
    fetchData("intenerary", "/master/rencana-perjalanan");
    fetchData("condition", "/master/persyaratan");
    fetchData("provision", "/master/perlengkapan");
  }, []);

  const fetchData = async (key, url) => {
    try {
      const response = await api.get(url);
      const formattedOptions = response.data.data.map((item) => ({
        name: item.title,
        value: item.id,
      }));
      setFormOptions((prevOptions) => ({
        ...prevOptions,
        [key]: formattedOptions,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddDetail = (type, idKey) => {
    const selectedOption = formOptions[type].find(
      (option) => option.value === formValues[idKey]
    );

    if (selectedOption) {
      if (
        (type === "feature" || type === "intenerary" || type === "condition") &&
        details.some((detail) => detail.type === type)
      ) {
        const updatedDetails = details.filter((detail) => detail.type !== type);
        setDetails([
          ...updatedDetails,
          {
            title: selectedOption.name,
            type: type,
            [`${type}_id`]: selectedOption.value,
          },
        ]);

        const updatedOptions = formOptions[type].map((option) => ({
          ...option,
          disabled:
            option.value === selectedOption.value ||
            updatedDetails.some(
              (detail) => detail[`${type}Id`] === option.value
            ),
        }));

        setFormOptions((prevOptions) => ({
          ...prevOptions,
          [type]: updatedOptions,
        }));
      } else {
        const newDetail = {
          title: selectedOption.name,
          type: type,
          [`${type}_id`]: selectedOption.value,
        };

        setDetails([...details, newDetail]);

        setFormValues((prevValues) => ({
          ...prevValues,
          [idKey]: null,
        }));

        const updatedOptions = formOptions[type].map((option) => ({
          ...option,
          disabled:
            option.value === selectedOption.value ||
            details.some((detail) => detail[`${type}_id`] === option.value),
        }));

        setFormOptions((prevOptions) => ({
          ...prevOptions,
          [type]: updatedOptions,
        }));
      }
    }
  };

  const handleDeleteDetail = (index) => {
    const deletedDetail = details[index];
    const updatedDetails = details.filter((_, idx) => idx !== index);
    setDetails(updatedDetails);

    const updatedOptions = formOptions[deletedDetail.type].map((option) => {
      const stillSelected = updatedDetails.some(
        (detail) => detail[`${deletedDetail.type}_id`] === option.value
      );
      return {
        ...option,
        disabled: stillSelected,
      };
    });

    setFormOptions((prevOptions) => ({
      ...prevOptions,
      [deletedDetail.type]: updatedOptions,
    }));
  };

  return (
    <>
      <div>
        <h2>Details Spec:</h2>
      </div>
      <div className="grid-form-spec">
        <div>
          {/* <div>
          <div>Title spec</div>
          <InputText
            value={titleSpec}
            onChange={(e) => setTitleSpec(e.target.value)}
          />
        </div> */}

          {Object.keys(formOptions).map((type) => (
            <div key={type} className="mb-3">
              <div className="title-input">
                Form {type.charAt(0).toUpperCase() + type.slice(1)}
              </div>
              <div className="list-spec">
                <div className="d1">
                  <Dropdown
                    value={formValues[`${type}Id`]}
                    onChange={(e) =>
                      setFormValues({ ...formValues, [`${type}Id`]: e.value })
                    }
                    options={formOptions[type]}
                    optionLabel="name"
                    placeholder="Select Spec"
                    className="w-full md:w-14rem"
                  />
                </div>
                <div className="d2">
                  <Button onClick={() => handleAddDetail(type, `${type}Id`)}>
                    Tambah {type.charAt(0).toUpperCase() + type.slice(1)}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="card-spec">
            <h3>Spec yang anda pilih:</h3>
            {details.map((detail, index) => (
              <div key={index} className="card-dalam">
                <div className="d1">
                  <strong>{detail.title}</strong> ,
                  <div className="mt-1">Type: {detail.type}</div>
                </div>
                <div className="d2">
                  <Button
                    icon="pi pi-trash"
                    className="p-button-danger p-button-sm"
                    style={{ marginLeft: "10px" }}
                    onClick={() => handleDeleteDetail(index)}>
                    <span className="pl-1">Hapus</span>
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FromSPec;
