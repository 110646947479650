import React from "react";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import TableDinamis from "../../util/dynamic/tableDinamis";
import moment from "moment";
const TransaksiPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <TableDinamis
        title="Transaksi Detail"
        headerComponents={[]}
        id="transaksi"
        path={`/transaksi/${id}`}
        onRefresh={0}
        kolom={[
          {
            field: "code",
            label: "Kode",
            sort: true,
          },
          {
            field: "nominal",
            label: "Nominal",
            type: "currency",
            sort: true,
          },
          {
            field: "status",
            label: "Status",
            sort: true,
          },
          {
            field: "createdAt",
            label: "Created At",
            type: "date",
            format: "DD MMM yyyy",
            sort: true,
          },
          {
            field: "expire_date",
            label: "Expired At",
            type: "custom",
            sort: true,
            component: (rowData) => (
              <span>
                {rowData.expire_date
                  ? moment(rowData.expire_date).format("lll")
                  : "-"}
              </span>
            ),
          },
        ]}
        actionButtonTemplate={(rowData) => (
          <div className="action-buttons">
            <Button
              label="Detail"
              className="p-button-raised p-button-info mr-2"
              onClick={() =>
                window.open(
                  `http://103.127.96.205:4444/pdf/invoice/${id}`,
                  "_blank"
                )
              }
            />
          </div>
        )}
      />
    </div>
  );
};

export default TransaksiPage;
