import React, { Fragment, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { ButtonGroup } from "primereact/buttongroup";
import { InputText } from "primereact/inputtext";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../util/axiosConfig";
import "react-toastify/dist/ReactToastify.css";

const Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form, setForm] = useState({
    title: "",
    data: [{ title: "", description: "" }],
  });

  useEffect(() => {
    if (id) {
      api.get(`/master/rencana-perjalanan/${id}`).then((response) => {
        const { title, data } = response.data.data;
        setForm({
          title: title || "",
          data: data || [{ title: "", description: "" }],
        });
      });
    }
  }, [id]);

  const handleAdd = () => {
    setForm((prevState) => ({
      ...prevState,
      data: [...prevState.data, { title: "", description: "" }],
    }));
  };

  const handleDelete = (index) => {
    if (form.data.length > 1) {
      setForm((prevState) => ({
        ...prevState,
        data: prevState.data.filter((_, i) => i !== index),
      }));
    }
  };

  const handleChange = (index, field, value) => {
    const newData = [...form.data];
    newData[index] = { ...newData[index], [field]: value };
    setForm((prevState) => ({
      ...prevState,
      data: newData,
    }));
  };

  const handleTitleChange = (e) => {
    setForm((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const dataToSend = {
      title: form.title,
      data: form.data,
    };

    const endpoint = id
      ? `/master/rencana-perjalanan/${id}`
      : "/master/rencana-perjalanan";
    const method = id ? "put" : "post";

    api[method](endpoint, dataToSend, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      navigate("/master/itinerary"); // Navigate to success page
    });
  };

  return (
    <div className="w-full">
      <form onSubmit={onSubmit}>
        <div className="flex flex-column gap-2">
          <label htmlFor="title">Title</label>
          <InputText
            value={form.title}
            onChange={handleTitleChange}
            aria-describedby="title-help"
          />
        </div>
        {form.data.map((item, index) => (
          <div key={`data-${index}`} className="flex flex-column gap-2">
            <label htmlFor={`title-${index}`}>Item Title</label>
            <InputText
              value={item.title}
              onChange={(e) => handleChange(index, "title", e.target.value)}
              aria-describedby={`title-help-${index}`}
            />
            <label htmlFor={`description-${index}`}>Item Description</label>
            <InputText
              value={item.description}
              onChange={(e) =>
                handleChange(index, "description", e.target.value)
              }
              aria-describedby={`description-help-${index}`}
            />
            <ButtonGroup>
              {index === form.data.length - 1 && (
                <Button label="Add" icon="pi pi-plus" onClick={handleAdd} />
              )}
              <Button
                label="Delete"
                icon="pi pi-trash"
                onClick={() => handleDelete(index)}
                disabled={form.data.length === 1}
              />
            </ButtonGroup>
          </div>
        ))}
        <Button type="submit" label="Submit" />
      </form>
    </div>
  );
};

export default Page;
